import CryptoJS from 'crypto-js';

export default class EncryptService {
    static secretKey: string = 'bR9gH1lD9cL5eH2e';

    static encryptData(data: any) {
        try {
            let cypher
            let k = CryptoJS.enc.Utf8.parse(this.secretKey)
            cypher = CryptoJS.AES.encrypt(data, k, {mode: CryptoJS.mode.ECB})
            cypher = CryptoJS.enc.Base64.stringify(cypher.ciphertext)
            cypher = cypher.replace(/\//g, "code");
            return cypher
        } catch (e) {
           console.log(e);
        }
    }

    static dencryptData(data: string) {
        try {
            let decrypt = data.replace(/code/g, "/");
            let cypher
            let k = CryptoJS.enc.Utf8.parse(this.secretKey)
            cypher = CryptoJS.AES.decrypt(decrypt, k, { mode: CryptoJS.mode.ECB })
            cypher = CryptoJS.enc.Base64.stringify(cypher);
            var decodignPlainText = atob(cypher);
            var utf8 = decodeURIComponent(escape(decodignPlainText));
            return JSON.parse(utf8);
        } catch (e) {
            console.log("Error", e);
        }
    }
}
