import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import validateRFC from 'validate-rfc';
import authGuardService from '../../service/authGuardService';
import UserService from '../../service/user.service';
import toast, { Toaster } from 'react-hot-toast';
import tokenServices from '../../service/token.service';

export const NewClient = (props: any) => {
    const history = useHistory();
    const token = authGuardService.getToken()

    const [checked, setChecked] = useState<boolean>(true);
    const [ver, noVer] = useState<boolean>(true);
    const [displayBasic, setDisplayBasic] = useState(false)
    const [modalQuit, setModalQuit] = useState(false)

    const [telefono, setTelefono] = useState('')
    const [noChange, setNoChanged] = useState(true)

    const [colorBackground, setColorBackground] = useState('#5041FF')
    const [textColor, setTextColor] = useState('#FFFFFF')
    const [buttonColor, setButtonColor] = useState('#f2c91a')
    const [textButtonColor, setTextButtonColor] = useState('#000000')

    const [jsonSent, setJsonSent] = useState<any>(null)

    const [values, setValues] = useState<any>({
        nombre: { value: '', valid: true },
        sector: { value: '', valid: true },
        razonSocial: { value: '', valid: true },
        rfc: { value: '', valid: true },
        calle: { value: '', valid: true },
        numeroExterior: { value: '', valid: true },
        numeroInterior: { value: '', valid: true },
        piso: { value: '', valid: true },
        colonia: { value: '', valid: true },
        municipio: { value: '', valid: true },
        ciudad: { value: '', valid: true },
        cp: { value: '', valid: true },
    })
    const [facturationValues, setFacturationValues] = useState<any>({
        calle: { value: '', valid: true },
        numeroExterior: { value: '', valid: true },
        numeroInterior: { value: '', valid: true },
        piso: { value: '', valid: true },
        colonia: { value: '', valid: true },
        municipio: { value: '', valid: true },
        ciudad: { value: '', valid: true },
        cp: { value: '', valid: true },
    })

    const changeValuesHandler = (e: { target: { name: any; value: any; }; }) => {
        setNoChanged(false)
        setValues({ ...values, [e.target.name]: { value: e.target.value, valid: e.target.value.length > 0 } })
    }

    const changeFacturationValuesHandler = (e: { target: { name: any; value: any; }; }) => {
        setFacturationValues({ ...facturationValues, [e.target.name]: { value: e.target.value, valid: e.target.value.length > 0 } })
    }

    const onHide = () => {
        setDisplayBasic(false)
    }

    const createDesign = async (cliId: any) => {
        const design = {
            "cliId": cliId,
            "fondo": colorBackground,
            "btn": buttonColor,
            "txt": textColor,
            "txtbtn": textButtonColor,
            "logo": 'logo',
            "tipogr": 'tipogr',
        }
        await UserService.addDesign(design, cliId, token).then(response => {
        });
    }

    const onClick = async () => {
        /* const representativesValidate = validateAllRepresentatives()
        const isRepresentativesValid = !(representativesValidate.includes(false)) */
        var newJson: any = {};
        var newJsonFacturacion: any = {};
        const token = authGuardService.getToken()

        for (let field in values) {
            if (field === 'rfc') {
                newJson[field] = { value: values[field].value, valid: validateRFC(values[field].value).isValid };
            } else {
                newJson[field] = { value: values[field].value, valid: values[field].value.length > 0 };
            }
        }

        if (!ver) {
            for (let field in facturationValues) {
                newJsonFacturacion[field] = { value: facturationValues[field].value, valid: facturationValues[field].value.length > 0 };
            }
            setFacturationValues(newJsonFacturacion)
        }

        setValues(newJson)

        if (newJson.nombre.valid && newJson.sector.valid && telefono.length == 10 && validateAllRepresentatives()/* && newJson.razonSocial.valid && newJson.rfc.valid && newJson.calle.valid && newJson.colonia.valid && newJson.municipio.valid && newJson.ciudad.valid && newJson.cp.valid */) {
            /* if (isRepresentativesValid) { */
            if (!ver) {
                /* if (newJsonFacturacion.calle.valid && newJsonFacturacion.colonia.valid && newJsonFacturacion.municipio.valid && newJsonFacturacion.ciudad.valid && newJsonFacturacion.cp.valid) { */
                const JSONtoSend = {
                    usuId: 1,
                    nombre: newJson.nombre.value,
                    sector: newJson.sector.value,
                    tel: telefono,
                    rs: newJson.razonSocial.value,
                    rfc: newJson.rfc.value,
                    calle: newJson.calle.value,
                    interior: newJson.numeroInterior.value,
                    piso: newJson.piso.value,
                    col: newJson.colonia.value,
                    mun: newJson.municipio.value,
                    cd: newJson.ciudad.value,
                    cp: newJson.cp.value,
                    calle2: newJsonFacturacion.calle.value,
                    interior2: newJsonFacturacion.numeroInterior.value,
                    piso2: newJsonFacturacion.piso.value,
                    col2: newJsonFacturacion.colonia.value,
                    mun2: newJsonFacturacion.municipio.value,
                    cd2: newJsonFacturacion.ciudad.value,
                    cp2: newJsonFacturacion.cp.value,
                    status: 'PROSPECTO'
                }


                await UserService.agregaUsuario(JSONtoSend, token).then(data => {
                    if (data) {
                        setJsonSent(data.data)
                        if (representatives) {
                            representatives.map(async (representative: { nombre: any; telefono: any; correo: any; puesto: any; area: any; subArea: any; numeroEmpleado: any; }) => {
                                const JSONRepresentative = JSON.stringify({
                                    "cliId": data.data.cliId,
                                    "nombre": representative.nombre,
                                    "tel": representative.telefono,
                                    "email": representative.correo,
                                    "puesto": representative.puesto,
                                    "area": representative.area,
                                    "sub": representative.subArea,
                                    "num": representative.numeroEmpleado
                                })
                                return await UserService.addRepresentative(JSONRepresentative, token)
                            })
                        }

                        createDesign(data.data.cliId)
                        setDisplayBasic(true)
                    } else {
                        notify()
                    }
                }).catch(error => {
                    notify()
                });

                /* } */
            } else {
                const JSONtoSend = {
                    usuId: 1,
                    nombre: newJson.nombre.value,
                    sector: newJson.sector.value,
                    tel: telefono,
                    rs: newJson.razonSocial.value,
                    rfc: newJson.rfc.value,
                    calle: newJson.calle.value,
                    interior: newJson.numeroInterior.value,
                    piso: newJson.piso.value,
                    col: newJson.colonia.value,
                    mun: newJson.municipio.value,
                    cd: newJson.ciudad.value,
                    cp: newJson.cp.value,
                    status: 'PROSPECTO'
                }

                await UserService.agregaUsuario(JSONtoSend, token).then(data => {
                    if (data) {
                        setJsonSent(data.data)
                        if (representatives) {
                            representatives.map(async (representative: { nombre: any; telefono: any; correo: any; puesto: any; area: any; subArea: any; numeroEmpleado: any; }) => {
                                const JSONRepresentative = JSON.stringify({
                                    "cliId": data.data.cliId,
                                    "nombre": representative.nombre.value,
                                    "tel": representative.telefono.value,
                                    "email": representative.correo.value,
                                    "puesto": representative.puesto.value,
                                    "area": representative.area.value,
                                    "sub": representative.subArea.value,
                                    "num": representative.numeroEmpleado.value
                                })
                                return await UserService.addRepresentative(token, JSONRepresentative)
                            })
                        }
                        createDesign(data.data.cliId)
                        setDisplayBasic(true)
                    }
                }).catch(error => {
                    notify()
                });
            }
            /* } */
        } else {
            
        }
    }

    const [representatives, setRepresentatives] = useState<any>([{
        nombre: { value: '', valid: true },
        telefono: { value: '', valid: true },
        correo: { value: '', valid: true },
        puesto: { value: '', valid: true },
        area: { value: '', valid: true },
        subArea: { value: '', valid: true },
        numeroEmpleado: { value: '', valid: true },
    }])

    const addRepresentative = () => {
        setRepresentatives((representatives: any) => [...representatives, {
            nombre: { value: '', valid: true },
            telefono: { value: '', valid: true },
            correo: { value: '', valid: true },
            puesto: { value: '', valid: true },
            area: { value: '', valid: true },
            subArea: { value: '', valid: true },
            numeroEmpleado: { value: '', valid: true },
        }]);
    }

    const deleteRepresentative = (id: any) => {
        if (id && id > 0) {
            const updatedAreas = [...representatives];
            updatedAreas.splice(id, 1);
            setRepresentatives(updatedAreas)
        }
    }

    const changeRepresentativesValues = (e: any, index: any) => {
        const updatedAreas = [...representatives];
        updatedAreas[index] = { ...representatives[index], [e.target.name]: { value: e.target.value, valid: e.target.name == 'correo' ? isValidEmail(e.target.value) : e.target.value.length > 0 } };
        setRepresentatives(updatedAreas);
    }

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function validateAllRepresentatives() {
        if (representatives.length > 0) {
            const isValid = representatives.map((singleRepresentatives: any, index: any) => {
                return singleRepresentatives.correo.valid
            })

            if (isValid.includes(false)) return false
            else return true
        } else {
            return true
        }
    }
    const returnToPreviousScreen = () => {
        if (noChange) {
            tokenServices.checkTokenTimeout()
            history.push('/reportes')
        }
        else setModalQuit(true)
    }

    const notify = () => toast.custom(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px',
            gap: '8px',
            background: '#FDEDED',
            borderRadius: '4px',
            width: '569px',
            height: '36px'
        }}>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <i
                    className="pi pi-exclamation-circle"
                    style={{
                        color: '#F04437',
                        marginRight: 5,
                        fontSize: '1.2rem'
                    }}
                />
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '125%',
                        color: '#5F2120'
                    }}>Ocurrió algo inesperado. No se pudo guardar la información. Inténtalo nuevamente.</p>
            </span>
        </div >
    );

    const travelToEdit = () => {
        tokenServices.checkTokenTimeout()
        history.push({
            pathname: '/editClient',
            state: { data: jsonSent }
        })
    }


    return (
        <section className="sect-body" style={{ marginTop: '25px' }}>
            <div className='sect-content'>
                <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center header-tab">
                    <div>
                        <h4 className="m-0">Nuevo cliente</h4>
                    </div>
                    <div className='text-right'>
                        <Button label="Cancelar" className="p-button-text pr-5" onClick={returnToPreviousScreen} />
                        <Button label="Agregar cliente" icon="pi pi-save" iconPos="right" className="" onClick={() => onClick()} disabled={noChange} />
                    </div>
                </div>
                <Divider />

                <div style={{
                    maxHeight: `80vh`,
                    overflow: "scroll"
                }}>
                    <div className='card'>

                        <div className='grid'>
                            <Toaster
                                position="top-center"
                                containerClassName=""
                                containerStyle={{
                                    top: 80,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                }}
                            />
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Nombre comercial</label>
                                    <InputText
                                        id="nombre"
                                        name="nombre"
                                        className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                        type="text"
                                        placeholder='Ej. Banco'
                                        value={values.nombre.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>

                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Sector</label>
                                    <InputText
                                        id="sector"
                                        name="sector"
                                        className={values.sector.valid ? "p-d-block" : "p-invalid block"}
                                        type="text" placeholder='Financiero'
                                        value={values.sector.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>

                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Teléfono de la empresa</label>
                                    <InputMask
                                        id="telefono"
                                        mask="(999) 999 9999"
                                        className={telefono.length == 10 ? "p-d-block" : "p-invalid block"}
                                        value={telefono}
                                        unmask={true}
                                        placeholder="(__) ___-____"
                                        onChange={(e) => setTelefono(e.value)}
                                    ></InputMask>
                                </div>
                            </div>

                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Razón social</label>
                                    <InputText
                                        id="razonSocial"
                                        name="razonSocial"
                                        /* className={values.razonSocial.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. Banco Azteca, S.A. Institución de...'
                                        value={values.razonSocial.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>

                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">RFC</label>
                                    <InputText
                                        id="rfc"
                                        name="rfc"
                                        /* className={values.rfc.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. BAI-020523-6Y8'
                                        value={values.rfc.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <Divider className='p-4' />

                        <div className='grid'>
                            <div className='col-12 md:col-12'>
                                <h5>Dirección</h5>
                            </div>
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Calle y número exterior</label>
                                    <InputText
                                        id="calle"
                                        name="calle"
                                        /* className={values.calle.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. AV FRACC DEL RIO FRIO 419'
                                        value={values.calle.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-2'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Número interior</label>
                                    <InputText
                                        id="numeroInterior"
                                        name="numeroInterior"
                                        className="p-d-block"
                                        type="text"
                                        placeholder=''
                                        value={values.numeroInterior.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-2'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Piso</label>
                                    <InputText
                                        id="piso"
                                        name="piso"
                                        className="p-d-block"
                                        type="text"
                                        placeholder=''
                                        value={values.piso.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Colonia</label>
                                    <InputText
                                        id="colonia"
                                        name="colonia"
                                        /* className={values.colonia.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. FRACCIONAMIENTO IND. DEL MORAL'
                                        value={values.colonia.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Municipio o alcaldía</label>
                                    <InputText
                                        id="municipio"
                                        name="municipio"
                                        /* className={values.municipio.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. IZTAPALAPA'
                                        value={values.municipio.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Ciudad</label>
                                    <InputText
                                        id="ciudad"
                                        name="ciudad"
                                        /*  className={values.ciudad.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. Ciudad de México'
                                        value={values.ciudad.value}
                                        onChange={changeValuesHandler}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-4'>
                                <div className="p-field">
                                    <label htmlFor="username1" className="p-d-block">Código Postal</label>
                                    <InputText
                                        id="cp"
                                        name="cp"
                                        /* className={values.cp.valid ? "p-d-block" : "p-invalid block"} */
                                        type="text"
                                        placeholder='Ej. 90109'
                                        value={values.cp.value}
                                        onChange={changeValuesHandler}
                                        keyfilter='int'
                                        maxLength={5}
                                    />
                                </div>
                            </div>
                            <div className='col-12 md:col-12'>
                                <div className="p-field-checkbox">
                                    <Checkbox inputId="city1" value="Chicago" checked={ver} onChange={e => noVer(e.checked)} />
                                    <label htmlFor="city1">Utilizar esta dirección para facturación</label>
                                </div>
                            </div>
                        </div>

                        <Divider className='p-4' />
                        {ver ||
                            <div className='grid'>
                                <div className='col-12 md:col-12'>
                                    <h5>Dirección de facturación</h5>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Calle y número exterior</label>
                                        <InputText
                                            id="calle"
                                            name="calle"
                                            /* className={facturationValues.calle.valid ? "p-d-block" : "p-invalid block"} */
                                            type="text"
                                            placeholder='Ej. AV FRACC DEL RIO FRIO 419'
                                            value={facturationValues.calle.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-2'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Número interior</label>
                                        <InputText
                                            id="numeroInterior"
                                            name="numeroInterior"
                                            className="p-d-block"
                                            type="text"
                                            placeholder=''
                                            value={facturationValues.numeroInterior.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-2'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Piso</label>
                                        <InputText
                                            id="piso"
                                            name="piso"
                                            className="p-d-block"
                                            type="text"
                                            placeholder=''
                                            value={facturationValues.piso.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Colonia</label>
                                        <InputText
                                            id="colonia"
                                            name="colonia"
                                            /* className={facturationValues.colonia.valid ? "p-d-block" : "p-invalid block"} */
                                            type="text"
                                            placeholder='Ej. FRACCIONAMIENTO IND. DEL MORAL'
                                            value={facturationValues.colonia.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Municipio o alcaldía</label>
                                        <InputText
                                            id="municipio"
                                            name="municipio"
                                            /* className={facturationValues.municipio.valid ? "p-d-block" : "p-invalid block"} */
                                            type="text"
                                            placeholder='Ej. IZTAPALAPA'
                                            value={facturationValues.municipio.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Ciudad</label>
                                        <InputText
                                            id="ciudad"
                                            name="ciudad"
                                            /* className={facturationValues.ciudad.valid ? "p-d-block" : "p-invalid block"} */
                                            type="text"
                                            placeholder='Ej. Ciudad de México'
                                            value={facturationValues.ciudad.value}
                                            onChange={changeFacturationValuesHandler}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <div className="p-field">
                                        <label htmlFor="username1" className="p-d-block">Código Postal</label>
                                        <InputText
                                            id="cp"
                                            name="cp"
                                            /* className={facturationValues.cp.valid ? "p-d-block" : "p-invalid block"} */
                                            type="text"
                                            placeholder='Ej. 90109'
                                            value={facturationValues.cp.value}
                                            onChange={changeFacturationValuesHandler}
                                            keyfilter='int'
                                            maxLength={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    <div className='card'>

                        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <h5>Representantes de la empresa</h5>
                            </div>
                            <div className='text-right'>
                                <Button label="Agregar otro" icon="pi pi-plus" iconPos="right" className="p-button-outlined" onClick={() => addRepresentative()} />
                            </div>
                        </div>

                        {
                            representatives.map((representante: any, index: any) => {
                                return (
                                    <div className='py-5' key={index}>
                                        <div className="grid flex md:align-items-center">
                                            <div className='col-6'>
                                                <h6 className='m-0'>Representante principal</h6>
                                            </div>
                                            <div className='col-6 text-right'>
                                                {index != 0 &&
                                                    < Button icon="pi pi-times" className="p-button-rounded" tooltip="Eliminar representante" tooltipOptions={{ position: 'top' }} onClick={() => deleteRepresentative(index)} />
                                                }
                                            </div>
                                        </div>
                                        <div className='grid'>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Nombre completo</label>
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-user" />
                                                        <InputText
                                                            id="nombre"
                                                            name="nombre"
                                                            type="text"
                                                            placeholder='Nombre y apellidos'
                                                            /* className={representatives[index].nombre.valid ? "p-d-block" : "p-invalid block"} */
                                                            value={representatives[index].nombre.value}
                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Celular</label>
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-mobile" />
                                                        <InputMask
                                                            id="telefono"
                                                            name='telefono'
                                                            mask="(999) 999 9999"
                                                            /* className={representatives[index].telefono.valid ? "p-d-block" : "p-invalid block"} */
                                                            value={representatives[index].telefono.value}
                                                            unmask={true}
                                                            placeholder="(__) ___-____"
                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                        ></InputMask>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Correo electrónico</label>
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-envelope" />
                                                        <InputText
                                                            id="correo"
                                                            name="correo"
                                                            type="text"
                                                            placeholder='micorreo@mail.com'
                                                            className={representatives[index].correo.valid ? "p-d-block" : "p-invalid block"}
                                                            value={representatives[index].correo.value}
                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Puesto</label>
                                                    <InputText
                                                        id="puesto"
                                                        name="puesto"
                                                        type="text"
                                                        placeholder='Dir. de producto'
                                                        /* className={representatives[index].puesto.valid ? "p-d-block" : "p-invalid block"} */
                                                        value={representatives[index].puesto.value}
                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Área</label>
                                                    <InputText
                                                        id="area"
                                                        name="area"
                                                        type="text"
                                                        placeholder='Crédito'
                                                        /* className={representatives[index].area.valid ? "p-d-block" : "p-invalid block"} */
                                                        value={representatives[index].area.value}
                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-2'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Subárea</label>
                                                    <InputText
                                                        id="subArea"
                                                        name="subArea"
                                                        type="text"
                                                        placeholder='producto'
                                                        /* className={representatives[index].subArea.valid ? "p-d-block" : "p-invalid block"} */
                                                        value={representatives[index].subArea.value}
                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-2'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Número de empleado</label>
                                                    <InputText
                                                        id="numeroEmpleado"
                                                        name="numeroEmpleado"
                                                        type="text"
                                                        placeholder='producto'
                                                        /* className={representatives[index].numeroEmpleado.valid ? "p-d-block" : "p-invalid block"} */
                                                        value={representatives[index].numeroEmpleado.value}
                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            )
                        }


                        <Divider className='p-4' />
                        <div className='text-center fond-gris'>
                            <Button label="Aregar otro representante" className="p-button-link p-button-raised" icon="pi pi-plus" iconPos="left" onClick={() => addRepresentative()} />
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="" visible={displayBasic} style={{ width: 'auto' }} onHide={() => onHide()}>
                <div className='text-center'>
                    <img className="log-olv" src='../../assets/layout/images/check.png' />
                    <h4 className='pb-3'>¡Creación de cliente exictosa!</h4>
                    <p className='pb-3'>Ahora personaliza el Clic&Read con el logo y los colores del cliente</p>
                    <Link to="/reportes"><Button type="button" label="Finalizar" className='p-button-outlined mr-3'></Button></Link>
                    <Button type="button" label="Personalizar" onClick={() => { travelToEdit() }}></Button>
                </div>
            </Dialog>

            <Dialog header="" visible={modalQuit} style={{ width: 'auto' }} onHide={() => setModalQuit(false)}>
                <div className='text-center'>
                    <img className="log-olv" src='../../assets/layout/images/warming.png' />
                    <h4 className='pb-3'>¿Deseas salir sin agregar cliente?</h4>
                    <p className='pb-3'>Se perderán los datos ingresados.</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button type="button" label="Seguir editando" className='p-button-outlined mr-3' onClick={() => { setModalQuit(false) }} style={{ width: '50%' }} />
                        <Button type="button" label="Salir" onClick={() => {
                            tokenServices.checkTokenTimeout()
                            history.push('/reportes')
                        }} style={{ width: '50%' }} />
                    </div>
                </div>
            </Dialog>

        </section>
    );
}