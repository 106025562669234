import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Button as ButtonPrime } from 'primereact/button';
import Button from '@atlaskit/button/standard-button';
import { Checkbox } from 'primereact/checkbox';
import { ColorPicker } from 'primereact/colorpicker';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import validateRFC from 'validate-rfc';
import authGuardService from '../../service/authGuardService';
import UserService from '../../service/user.service';
import Dropzone from "./Dropzone";
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import { InputSwitch } from 'primereact/inputswitch';
import toast, { Toaster } from 'react-hot-toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import tokenServices from '../../service/token.service';
import { Password } from 'primereact/password';

export const EditClient = (props: any) => {

    const location: any = useLocation()
    const cliId = location?.state?.data?.cliId
    const history = useHistory();
    const imageRef = React.useRef(null);
    const token = authGuardService.getToken()
    const [checked, setChecked] = useState<boolean>(location?.state?.data?.calle2 ? true : false);
    const [activeIndex1, setActiveIndex1] = useState(1);
    const [activeIndex2, setActiveIndex2] = useState(0);
    const [ver, noVer] = useState<boolean>(!location?.state?.data?.calle2);
    const [color1, setColor1] = useState<any>('#01963A');
    const [color2, setColor2] = useState<any>('#FFFFFF');
    const [color3, setColor3] = useState<any>('#F2C91A');
    const [color4, setColor4] = useState<any>('#000000');
    const [selectedCity1, setSelectedCity1] = useState<any>(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic1, setDisplayBasic1] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [displayBasic3, setDisplayBasic3] = useState(false);
    const [image, setImage] = React.useState<any>("");
    const [result, setResult]: any = React.useState();
    const [hideActionButtons, setHideActionButtons] = useState<boolean>(false)
    const [modalQuit, setModalQuit] = useState(false)
    const [changeMadeInData, setChangeMadeInData] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false);

    const [colorBackground, setColorBackground] = useState('#4F49EF')
    const [textColor, setTextColor] = useState('#FFFFFF')
    const [buttonColor, setButtonColor] = useState('#F2C91A')
    const [textButtonColor, setTextButtonColor] = useState('#000000')
    const [disId, setDisId] = useState('')
    const [typo, setTypo] = useState<any>('Roboto')
    const [selectedAlign, setSelectedAlign] = useState<any>('C')

    const [dataManagment, setDataManagement] = useState({
        "cliId": cliId,
        "cliDatId": 0,
        "api_url": "",
        "api_key": "",
        "api_uid": "",
        "aws_bucket": "",
        "aws_key": "",
        "aws_secret": "",
        "aws_region": "",
        "e_pdf": false,
        "e_json": false,
        "e_xml": false,
        "e_csv": false,
        "e_xls": false,
        "a_pdf": false,
        "a_json": false,
        "a_xml": false,
        "a_csv": false,
        "a_xls": false,
        "w_pdf": false,
        "w_json": false,
        "w_xml": false,
        "w_csv": false,
        "w_xls": false
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getPreviousDesign()
        getRepresentantes()
        getReceivingRepresentantes()
        getDataGestion()
    }, []);

    /* function handleUpdate() {
        setRepresentatives([])
        setReceivingRepresentative([])
        
        getPreviousDesign()
        getRepresentantes()
        getReceivingRepresentantes()
        getDataGestion()
    } */

    const getPreviousDesign = () => {
        UserService.getDesign(cliId, token).then(async response => {
            if (response.data._embedded.disenios.length > 0) {
                if (response.data._embedded.disenios[0]?.tipogr != 'tipogr') {
                    const designTypo = JSON.parse(response.data._embedded.disenios[0]?.tipogr)
                    setTypo(designTypo.typo)
                    setSelectedAlign(designTypo.align)
                }

                setDisId(response.data._embedded.disenios[0].disId)
                setColorBackground(response.data._embedded.disenios[0].fondo)
                setButtonColor(response.data._embedded.disenios[0].btn)
                setTextColor(response.data._embedded.disenios[0].txt)
                setTextButtonColor(response.data._embedded.disenios[0].txtbtn)
                setResult(response.data._embedded.disenios[0].logo == 'logo' ? null : response.data._embedded.disenios[0].logo)
            } else {
                const design = {
                    "cliId": cliId,
                    "fondo": colorBackground,
                    "btn": buttonColor,
                    "txt": textColor,
                    "txtbtn": textButtonColor,
                    "logo": 'logo',
                    "tipogr": 'tipogr',
                }
                await UserService.addDesign(design, cliId, token).then(response => {
                    console.log(response)
                });
            }
        });
    }

    const getRepresentantes = async () => {
        await UserService.getRepresentantes(token).then(representantesResponse => {
            if (representantesResponse.data._embedded.representantes.length > 0) {
                representantesResponse.data._embedded.representantes.map((singleRepresentante: any) => {
                    if (singleRepresentante.cliId == cliId) {
                        setRepresentatives((representatives: any) => [...representatives, {
                            repId: singleRepresentante.repId,
                            nombre: { value: singleRepresentante.nombre, valid: true },
                            telefono: { value: singleRepresentante.tel, valid: true },
                            correo: { value: singleRepresentante.email, valid: true },
                            puesto: { value: singleRepresentante.puesto, valid: true },
                            area: { value: singleRepresentante.area, valid: true },
                            subArea: { value: singleRepresentante.sub, valid: true },
                            numeroEmpleado: { value: singleRepresentante.num, valid: true },
                        }]);
                    }
                })
            }
        })
        setLoading(false)
    }

    const getReceivingRepresentantes = async () => {
        await UserService.getReceivingRepresentatives(cliId, token).then(representantesResponse => {
            if (representantesResponse.data._embedded.clienteReceptors.length == 0) {
                addReceivingRepresentative()
            } else {
                representantesResponse.data._embedded.clienteReceptors.map((singleRepresentante: any) => {
                    if (singleRepresentante.cliId == cliId) {
                        setReceivingRepresentative((receivingRepresentative: any) => [...receivingRepresentative, {
                            cliRecId: singleRepresentante.clirecId,
                            telefono: { value: singleRepresentante.telefono, valid: true },
                            correo: { value: singleRepresentante.email, valid: true },
                        }]);
                    }
                })
            }
        })
        setLoading(false)
    }

    const getDataGestion = async () => {
        await UserService.getDataManagement(cliId, token).then(async dataResponse => {
            if (dataResponse.data._embedded.clienteDatoses.length == 0) {
                const dataToSend = {
                    "cliId": cliId,
                    "api_url": "",
                    "api_key": "",
                    "api_uid": "",
                    "aws_bucket": "",
                    "aws_key": "",
                    "aws_secret": "",
                    "aws_region": "",
                    "e_pdf": 0,
                    "e_json": 0,
                    "e_xml": 0,
                    "e_csv": 0,
                    "e_xls": 0,
                    "a_pdf": 0,
                    "a_json": 0,
                    "a_xml": 0,
                    "a_csv": 0,
                    "a_xls": 0,
                    "w_pdf": 0,
                    "w_json": 0,
                    "w_xml": 0,
                    "w_csv": 0,
                    "w_xls": 0
                }
                await UserService.postDataManagement(dataToSend, token).then(dataPostReponse => {
                })
            } else {
                await UserService.getDataManagement(cliId, token).then(dataResponse => {
                    setDataManagement(dataResponse.data._embedded.clienteDatoses[0])
                })
            }
        })
    }

    function uploader(imageFile: any) {
        setChangeMadeInData('CR')
        setHideActionButtons(true)
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            setResult(e?.target?.result);
        });

        reader.readAsDataURL(imageFile);
    }

    const onHide = () => {
        setDisplayBasic(false)
    }
    const onClick = () => {
        setDisplayBasic(true)
    }
    const onClick1 = () => {
        setDisplayBasic1(true)
    }
    const onHide1 = () => {
        setDisplayBasic1(false)
    }
    const onClick2 = () => {
        setDisplayBasic2(true)
    }
    const onHide2 = () => {
        setDisplayBasic2(false)
    }
    const renderFooter = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => onHide()} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, desactivar" onClick={() => requestDeactivateClient()} autoFocus className='p-button-danger' />
            </div>
        );
    }
    const renderFooter1 = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => onHide1()} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, activar" onClick={() => requestDeactivateClient()} autoFocus />
            </div>
        );
    }
    const renderFooter2 = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => setDisplayBasic2(false)} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, eliminar" onClick={() => requestDelete()} autoFocus className='p-button-danger' />
            </div>
        );
    }

    const typography = [
        { name: 'Roboto', code: 'Roboto' },
        { name: 'Inter', code: 'Inter' },
        { name: 'Montserrat', code: 'Montserrat' },
        { name: 'Open Sans', code: 'OpenSans' }
    ];

    const onTypographyChange = (e: { value: any }) => {
        setTypo(e.value);
        setChangeMadeInData('CR')
        setHideActionButtons(true)
    }

    const alignLogo = [
        /*         { name: 'Arriba - Izquierda', code: 'UL' },
                { name: 'Arriba - Centrado', code: 'UC' },
                { name: 'Arriba - Derecha', code: 'UR' },*/
        { name: 'Centrado - Izquierda', code: 'CL' },
        { name: 'Centrado', code: 'C' },
        { name: 'Centrado - Derecha', code: 'CR' },
        /*  { name: 'Abajo - Izquierda', code: 'DL' },
          { name: 'Abajo - Centrado', code: 'DC' },
          { name: 'Abajo - Derecha', code: 'DR' } */
    ];

    const onAlignLogoChange = (e: { value: any }) => {
        setSelectedAlign(e.value);
        setChangeMadeInData('CR')
        setHideActionButtons(true)
    }

    const menu = useRef<any>(null);
    const items = [
        {
            label: 'Desactivar cliente',
            icon: 'pi pi-power-off',
            command: () => {
                onClick()
            }
        },
        {
            label: 'Activar cliente',
            icon: 'pi pi-power-off',
            command: () => {
                onClick1()
            }
        },
        {
            label: 'Eliminar cliente',
            icon: 'pi pi-trash',
            command: () => {
                onClick2()
            }
        }
    ]

    const [images, setImages] = useState<any>([]);

    const onDrop = useCallback(acceptedFiles => {
        setChangeMadeInData('CR')
        setHideActionButtons(true)
        setImage(acceptedFiles[0]);
        uploader(acceptedFiles[0]);
        // Loop through accepted files
        /* acceptedFiles.map((file: any) => {
            // Initialize FileReader browser API
            const reader = new FileReader();
            // onload callback gets called after the reader reads the file data
            reader.onload = function (e) {
                // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it. 
                setImages((prevState: any) => [...prevState, { id: cuid(), src: e?.target?.result }]);
            };
            // Read the file as Data URL (since we accept only images)
            reader.readAsDataURL(file);
            return file;
        }); */
    }, []);

    const [values, setValues] = useState<any>({
        activo: location?.state?.data?.activo,
        usuId: { value: location?.state?.data?.usuId },
        nombre: { value: location?.state?.data?.nombre || '', valid: true },
        sector: { value: location?.state?.data?.sector || '', valid: true },
        razonSocial: { value: location?.state?.data?.rs || '', valid: true },
        rfc: { value: location?.state?.data?.rfc || '', valid: true },
        calle: { value: location?.state?.data?.calle || '', valid: true },
        numeroExterior: { value: location?.state?.data?.interior || '', valid: true },
        numeroInterior: { value: location?.state?.data?.interior || '', valid: true },
        piso: { value: location?.state?.data?.piso || '', valid: true },
        colonia: { value: location?.state?.data?.col || '', valid: true },
        municipio: { value: location?.state?.data?.mun || '', valid: true },
        ciudad: { value: location?.state?.data?.cd || '', valid: true },
        cp: { value: location?.state?.data?.cp || '', valid: true },
    })

    const [telefono, setTelefono] = useState(location?.state?.data?.tel || '')


    const changeValuesHandler = (e: { target: { name: any; value: any; }; }) => {
        setValues({ ...values, [e.target.name]: { value: e.target.value, valid: e.target.value.length > 0 } })
        setHideActionButtons(true)
        setChangeMadeInData('data')
    }

    const [facturationValues, setFacturationValues] = useState<any>({
        calle: { value: location?.state?.data?.calle2 || '', valid: true },
        numeroExterior: { value: location?.state?.data?.interior2 || '', valid: true },
        numeroInterior: { value: location?.state?.data?.interior2 || '', valid: true },
        piso: { value: location?.state?.data?.piso2 || '', valid: true },
        colonia: { value: location?.state?.data?.col2 || '', valid: true },
        municipio: { value: location?.state?.data?.mun2 || '', valid: true },
        ciudad: { value: location?.state?.data?.cd2 || '', valid: true },
        cp: { value: location?.state?.data?.cp2 || '', valid: true }
    })

    const changeFacturationValuesHandler = (e: { target: { name: any; value: any; }; }) => {
        setFacturationValues({ ...facturationValues, [e.target.name]: { value: e.target.value, valid: e.target.value.length > 0 } })
        setHideActionButtons(true)
        setChangeMadeInData('data')
    }

    const returnToOriginal = () => {
        setValues({
            usuId: { value: location?.state?.data?.usuId },
            nombre: { value: location?.state?.data?.nombre || '', valid: true },
            sector: { value: location?.state?.data?.sector || '', valid: true },
            razonSocial: { value: location?.state?.data?.rs || '', valid: true },
            rfc: { value: location?.state?.data?.rfc || '', valid: true },
            calle: { value: location?.state?.data?.calle || '', valid: true },
            numeroExterior: { value: location?.state?.data?.interior || '', valid: true },
            numeroInterior: { value: location?.state?.data?.interior || '', valid: true },
            piso: { value: location?.state?.data?.piso || '', valid: true },
            colonia: { value: location?.state?.data?.col || '', valid: true },
            municipio: { value: location?.state?.data?.mun || '', valid: true },
            ciudad: { value: location?.state?.data?.cd || '', valid: true },
            cp: { value: location?.state?.data?.cp || '', valid: true },
        })
        setFacturationValues({
            calle: { value: location?.state?.data?.calle2 || '', valid: true },
            numeroExterior: { value: location?.state?.data?.interior2 || '', valid: true },
            numeroInterior: { value: location?.state?.data?.interior2 || '', valid: true },
            piso: { value: location?.state?.data?.piso2 || '', valid: true },
            colonia: { value: location?.state?.data?.col2 || '', valid: true },
            municipio: { value: location?.state?.data?.mun2 || '', valid: true },
            ciudad: { value: location?.state?.data?.cd2 || '', valid: true },
            cp: { value: location?.state?.data?.cp2 || '', valid: true }
        })
        setModalQuit(false)
        setHideActionButtons(false)
        getPreviousDesign()
        setRepresentatives([])
        setReceivingRepresentative([])
        getRepresentantes()
        getReceivingRepresentantes()
        getDataGestion()
    }

    const requestUpdate = () => {
        setButtonLoading(true)
        var newJson: any = {};
        var newJsonFacturacion: any = {};
        const token = authGuardService.getToken()

        for (let field in values) {
            if (field === 'rfc') {
                newJson[field] = { value: values[field]?.value, valid: validateRFC(values[field]?.value).isValid };
            } else {
                newJson[field] = { value: values[field]?.value, valid: values[field]?.value?.length > 0 };
            }
        }

        if (!ver) {
            for (let field in facturationValues) {
                newJsonFacturacion[field] = { value: facturationValues[field]?.value, valid: facturationValues[field]?.value?.length > 0 };
            }
            setFacturationValues(newJsonFacturacion)
        }

        setValues(newJson)

        if (
            newJson.nombre.valid &&
            newJson.sector.valid &&
            telefono.length == 10 &&
            validateRepresentatives()/* &&
            newJson.razonSocial.valid &&
            newJson.rfc.valid &&
            newJson.calle.valid &&
            newJson.colonia.valid &&
            newJson.municipio.valid &&
            newJson.ciudad.valid &&
            newJson.cp.valid */) {
            if (!ver) {
                /* if (
                    newJsonFacturacion.calle.valid &&
                    newJsonFacturacion.colonia.valid &&
                    newJsonFacturacion.municipio.valid &&
                    newJsonFacturacion.ciudad.valid &&
                    newJsonFacturacion.cp.valid
                ) { */
                const JSONtoSend = {
                    usuId: newJson.usuId.value,
                    nombre: newJson.nombre.value,
                    sector: newJson.sector.value,
                    tel: telefono,
                    rs: newJson.razonSocial.value,
                    rfc: newJson.rfc.value,
                    calle: newJson.calle.value,
                    interior: newJson.numeroInterior.value,
                    piso: newJson.piso.value,
                    col: newJson.colonia.value,
                    mun: newJson.municipio.value,
                    cd: newJson.ciudad.value,
                    cp: newJson.cp.value,
                    calle2: newJsonFacturacion.calle.value,
                    interior2: newJsonFacturacion.numeroInterior.value,
                    piso2: newJsonFacturacion.piso.value,
                    col2: newJsonFacturacion.colonia.value,
                    mun2: newJsonFacturacion.municipio.value,
                    cd2: newJsonFacturacion.ciudad.value,
                    cp2: newJsonFacturacion.cp.value,
                    status: location?.state?.data?.status
                }
                UserService.editUser(location.state.data.cliId, token, JSONtoSend).then(data => {
                    if (data.status == 200) {
                        requestUpdateRepresentatives()
                    } else {
                        setButtonLoading(false)
                        notify()
                    }
                });
                /* } */
            } else {
                const JSONtoSend = {
                    usuId: newJson.usuId.value,
                    nombre: newJson.nombre.value,
                    sector: newJson.sector.value,
                    tel: telefono,
                    rs: newJson.razonSocial.value,
                    rfc: newJson.rfc.value,
                    calle: newJson.calle.value,
                    interior: newJson.numeroInterior.value,
                    piso: newJson.piso.value,
                    col: newJson.colonia.value,
                    mun: newJson.municipio.value,
                    cd: newJson.ciudad.value,
                    cp: newJson.cp.value,
                    calle2: '',
                    interior2: '',
                    piso2: '',
                    col2: '',
                    mun2: '',
                    cd2: '',
                    cp2: '',
                    status: location?.state?.data?.status
                }
                UserService.editUser(location.state.data.cliId, token, JSONtoSend).then(data => {
                    if (data.status == 200) {
                        requestUpdateRepresentatives()
                    } else {
                        setButtonLoading(false)
                        notify()
                    }
                });
            }
        } else {
            setButtonLoading(false)
            setButtonLoading(false)
        }
    }

    function validateRepresentatives() {
        if (representatives.length > 0) {
            const isValid = representatives.map((singleRepresentatives: any, index: any) => {
                return singleRepresentatives.correo.valid
            })

            if (isValid.includes(false)) return false
            else return true
        } else {
            return true
        }
    }

    const requestUpdateRepresentatives = () => {
        representatives.map((singleRepresentatives: any, index: any) => {
            if (singleRepresentatives.delete) {
                if (singleRepresentatives.repId) {
                    UserService.deleteRepresentante(singleRepresentatives.repId, token)
                }
            } else {
                if (singleRepresentatives.repId) {
                    const dataToSend = {
                        "repId": singleRepresentatives.repId,
                        "cliId": cliId,
                        "nombre": singleRepresentatives.nombre.value,
                        "tel": singleRepresentatives.telefono.value,
                        "email": singleRepresentatives.correo.value,
                        "puesto": singleRepresentatives.puesto.value,
                        "area": singleRepresentatives.area.value,
                        "sub": singleRepresentatives.subArea.value,
                        "num": singleRepresentatives.numeroEmpleado.value
                    }
                    UserService.updateRepresentante(singleRepresentatives.repId, dataToSend, token).then().catch(e => {
                        console.log(e)
                    })
                } else {
                    const dataToSend = {
                        "cliId": cliId,
                        "nombre": singleRepresentatives.nombre.value,
                        "tel": singleRepresentatives.telefono.value,
                        "email": singleRepresentatives.correo.value,
                        "puesto": singleRepresentatives.puesto.value,
                        "area": singleRepresentatives.area.value,
                        "sub": singleRepresentatives.subArea.value,
                        "num": singleRepresentatives.numeroEmpleado.value
                    }

                    UserService.addRepresentative(token, dataToSend).then((responseRepresentative: any) => {
                        const updatedAreas = [...representatives];
                        updatedAreas[index] = { ...representatives[index], ['repId']: responseRepresentative.data.repId };
                        setRepresentatives(updatedAreas);
                    })
                }
            }
        })
        setDisplayBasic3(true)
        setButtonLoading(false)
        setButtonLoading(false)
        setHideActionButtons(false)
    }

    const saveDesign = () => {
        const tipogr = JSON.stringify({ align: selectedAlign, typo })
        const design = {
            "disId": disId,
            "cliId": cliId,
            "fondo": colorBackground,
            "btn": buttonColor,
            "txt": textColor,
            "txtbtn": textButtonColor,
            "logo": result,
            "tipogr": tipogr,
        }
        UserService.updateDesign(design, token, disId).then(response => {
            if (response.status == 200) {
                setButtonLoading(false)
                setHideActionButtons(false)
                setDisplayBasic3(true)
            } else {
                setButtonLoading(false)
                notify()
            }
        });
    }

    const actionBodyTemplate = () => {
        return (
            <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                    <Button
                        {...props}
                        iconBefore={<MoreVerticalIcon label="more" />}
                        ref={triggerRef}
                    />
                )}
            >
                <DropdownItemGroup>
                    <DropdownItem>
                        <div>
                            <div style={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: '4px',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                            }}>
                                <InputSwitch checked={location?.state?.data ? location?.state?.data.activo : false} onChange={() => { requestDeactivateClient() }} />
                                <p style={{
                                    marginLeft: 16,
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    color: '#45474D',
                                }}>Desactivar Cliente</p>
                            </div>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                            <div style={{
                                marginBottom: 10,
                                marginLeft: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: '4px',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                            }}
                                onClick={() => { requestDelete() }}
                            >
                                <i className="pi pi-trash" style={{ 'fontSize': '1.5em', color: 'red' }}></i>
                                <p style={{
                                    marginLeft: 25,
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    color: '#45474D',
                                }}>Eliminar Cliente</p>
                            </div>
                        </div >
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
        );
    }

    const deactivateClient = () => {
        if (location?.state?.data?.activo) {
            setDisplayBasic(true)
        } else {
            setDisplayBasic1(true)
        }
    }
    const requestDeactivateClient = () => {
        setDisplayBasic(false)
        setDisplayBasic1(false)
        setDisplayBasic2(false)
        const newData = location?.state?.data;
        const token = authGuardService.getToken()
        if (newData.activo) {
            newData.activo = !newData.activo
            UserService.activateUser(newData.cliId, newData, token).then(data => {
                setDisplayBasic(false)
                setDisplayBasic1(false)
                setDisplayBasic2(false)
            });
        } else {
            newData.activo = !newData.activo
            UserService.activateUser(newData.cliId, newData, token).then(data => {
                setDisplayBasic(false)
                setDisplayBasic1(false)
                setDisplayBasic2(false)
            });
        }

    }
    const requestDelete = () => {
        const token = authGuardService.getToken()
        UserService.deleteUser(cliId, token).then(data => {
            setDisplayBasic2(false)
            tokenServices.checkTokenTimeout()
            history.push('/reportes')
        });
    }

    const notify = () => toast.custom(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px',
            gap: '8px',
            background: '#FDEDED',
            borderRadius: '4px',
            width: '569px',
            height: '36px'
        }}>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <i
                    className="pi pi-exclamation-circle"
                    style={{
                        color: '#F04437',
                        marginRight: 5,
                        fontSize: '1.2rem'
                    }}
                />
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '125%',
                        color: '#5F2120'
                    }}>Ocurrió algo inesperado. No se pudo guardar la información. Inténtalo nuevamente.</p>
            </span>
        </div >
    );

    const [representatives, setRepresentatives] = useState<any>([])

    const addRepresentative = () => {
        setRepresentatives((representatives: any) => [...representatives, {
            nombre: { value: '', valid: true },
            telefono: { value: '', valid: true },
            correo: { value: '', valid: true },
            puesto: { value: '', valid: true },
            area: { value: '', valid: true },
            subArea: { value: '', valid: true },
            numeroEmpleado: { value: '', valid: true },
        }]);
    }

    const deleteRepresentative = (index: any) => {
        if (index && index > 0) {
            const updatedAreas = [...representatives];
            updatedAreas[index] = { ...representatives[index], ['delete']: true };
            setRepresentatives(updatedAreas)
            setHideActionButtons(true)
            setChangeMadeInData('data')
        }
    }

    const changeRepresentativesValues = (e: any, index: any) => {
        const updatedAreas = [...representatives];
        updatedAreas[index] = { ...representatives[index], [e.target.name]: { value: e.target.value, valid: e.target.name == 'correo' ? isValidEmail(e.target.value) : e.target.value.length > 0 } };
        setRepresentatives(updatedAreas);
        setHideActionButtons(true)
        setChangeMadeInData('data')
    }


    const [receivingRepresentative, setReceivingRepresentative] = useState<any>([])

    const addReceivingRepresentative = () => {
        setReceivingRepresentative((receivingRepresentative: any) => [...receivingRepresentative, {
            telefono: { value: '', valid: true },
            correo: { value: '', valid: true },
        }]);
    }

    const deleteReceivingRepresentative = (index: any) => {
        if (index && index > 0) {
            const updatedAreas = [...receivingRepresentative];
            updatedAreas[index] = { ...receivingRepresentative[index], ['delete']: true };
            setReceivingRepresentative(updatedAreas)
            setHideActionButtons(true)
            setChangeMadeInData('dm')
        }
    }

    const changeReceivingRepresentativeValues = (e: any, index: any) => {
        const updatedAreas = [...receivingRepresentative];
        updatedAreas[index].changes = true
        updatedAreas[index] = { ...receivingRepresentative[index], [e.target.name]: { value: e.target.value, valid: e.target.name == 'correo' ? isValidEmail(e.target.value) : e.target.value.length > 0 } };
        setReceivingRepresentative(updatedAreas);
        setHideActionButtons(true)
        setChangeMadeInData('dm')
    }


    const updateDataManagement = () => {
        receivingRepresentative.map((singleRepresentatives: any, index: any) => {
            if (singleRepresentatives.telefono.value && singleRepresentatives.correo.value && singleRepresentatives.correo.valid && singleRepresentatives.telefono.valid) {
                if (singleRepresentatives.cliRecId && singleRepresentatives.changes) {
                    const dataToSend = {
                        "cliId": cliId,
                        "telefono": singleRepresentatives.telefono.value,
                        "email": singleRepresentatives.correo.value,
                    }
                    UserService.postReceivingRepresentatives(dataToSend, token).then(response => {
                        setDisplayBasic3(true)
                    }).catch(e => {
                        console.log(e)
                    })
                } else if (!singleRepresentatives.cliRecId) {
                    const dataToSend = {
                        "cliId": cliId,
                        "telefono": singleRepresentatives.telefono.value,
                        "email": singleRepresentatives.correo.value,
                    }

                    UserService.postReceivingRepresentatives(dataToSend, token).then((responseRepresentative: any) => {
                        const updatedAreas = [...receivingRepresentative];
                        updatedAreas[index] = { ...receivingRepresentative[index], ['cliRecId']: responseRepresentative.data.cliRecId };
                        setReceivingRepresentative(updatedAreas);
                        setDisplayBasic3(true)
                    })
                }
            }

            if (singleRepresentatives.delete || (singleRepresentatives.changes && singleRepresentatives.cliRecId)) {
                if (singleRepresentatives.cliRecId) {
                    UserService.deleteReceivingRepresentatives(singleRepresentatives.cliRecId, token)
                }
            }

        })

        UserService.putDataManagement(dataManagment, dataManagment.cliDatId, token).then().catch(e => {
            console.log(e)
        })

        setButtonLoading(false)
        setButtonLoading(false)
        setHideActionButtons(false)

        setButtonLoading(true)
        setButtonLoading(false)
    }

    const changeDataManagement = (value: any, name: string) => {
        setDataManagement({ ...dataManagment, [name]: value })

        setHideActionButtons(true)
        setChangeMadeInData('dm')
    }

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        loading ? <ProgressSpinner /> :
            <section className="sect-body">
                <div className='retun'>
                    <Link to="/reportes"><ButtonPrime label="Regresar" icon="pi pi-arrow-left" iconPos="left" className="p-button-text p-button-secondary p-button-sm" /></Link>
                </div>
                <div className='sect-content'>
                    <div className="grid flex md:align-items-center header-tab" style={{ overflow: 'auto' }}>
                        <div className='col-6'>
                            <h3 className="m-0">{values.nombre.value || ''}</h3>
                        </div>
                        <div className='col-6 text-right'>

                            {
                                hideActionButtons ?
                                    <div className='flex flex-column md:flex-row md:justify-content-end md:align-items-center'>
                                        <ButtonPrime label="Cancelar" className="p-button-text pr-5" onClick={() => { changeMadeInData ? setModalQuit(true) : setModalQuit(true) }} />
                                        <ButtonPrime label="Guardar" icon="pi pi-save" iconPos="right" onClick={() => { changeMadeInData == 'data' ? requestUpdate() : changeMadeInData == 'CR' ? saveDesign() : updateDataManagement() }} loading={buttonLoading} />
                                    </div>
                                    :
                                    <DropdownMenu
                                        trigger={({ triggerRef, ...props }) => (
                                            <Button
                                                {...props}
                                                iconBefore={<MoreVerticalIcon label="more" />}
                                                ref={triggerRef}
                                            />
                                        )}
                                    >
                                        <DropdownItemGroup>
                                            <DropdownItem>
                                                <div>
                                                    <div style={{
                                                        marginTop: 10,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        borderRadius: '4px',
                                                        flex: 'none',
                                                        order: 1,
                                                        flexGrow: 0,
                                                    }}>
                                                        <InputSwitch checked={location?.state?.data ? location?.state?.data.activo : false} onChange={() => { deactivateClient() }} />
                                                        <p style={{
                                                            marginLeft: 16,
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: 400,
                                                            fontSize: '16px',
                                                            lineHeight: '16px',
                                                            color: '#45474D',
                                                        }}>{location?.state?.data.activo ? 'Desactivar' : 'Activar'} Cliente</p>
                                                    </div>
                                                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                                                    <div style={{
                                                        marginBottom: 10,
                                                        marginLeft: 10,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        borderRadius: '4px',
                                                        flex: 'none',
                                                        order: 1,
                                                        flexGrow: 0,
                                                    }}
                                                        onClick={() => { setDisplayBasic2(true) }}
                                                    >
                                                        <i className="pi pi-trash" style={{ 'fontSize': '1.5em', color: 'red' }}></i>
                                                        <p style={{
                                                            marginLeft: 25,
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: 400,
                                                            fontSize: '16px',
                                                            lineHeight: '16px',
                                                            color: '#45474D',
                                                        }}>Eliminar Cliente</p>
                                                    </div>
                                                </div >
                                            </DropdownItem>
                                        </DropdownItemGroup>
                                    </DropdownMenu>
                            }
                        </div>

                    </div>

                    <TabView className='px-5'>
                        <TabPanel header="Datos de la empresa" disabled={hideActionButtons}>
                            <div style={{
                                maxHeight: `70vh`,
                                overflow: "scroll"
                            }}>
                                <div className='card' >
                                    <div className='grid'>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Nombre comercial</label>
                                                <InputText
                                                    id="nombre"
                                                    name="nombre"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    type="text"
                                                    placeholder='Ej. Banco'
                                                    value={values.nombre.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Sector</label>
                                                <InputText
                                                    id="sector"
                                                    name="sector"
                                                    className={values.sector.valid ? "p-d-block" : "p-invalid block"}
                                                    type="text" placeholder='Financiero'
                                                    value={values.sector.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Teléfono de la empresa</label>
                                                <InputMask
                                                    id="telefono"
                                                    mask="(999) 999 9999"
                                                    className={telefono.length == 10 ? "p-d-block" : "p-invalid block"}
                                                    value={telefono}
                                                    unmask={true}
                                                    placeholder="(__) ___-____"
                                                    onChange={(e) => setTelefono(e.value)}
                                                ></InputMask>
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Razón social</label>
                                                <InputText
                                                    id="razonSocial"
                                                    name="razonSocial"
                                                    /* className={values.razonSocial.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. Banco Azteca, S.A. Institución de...'
                                                    value={values.razonSocial.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div >
                                        </div >
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">RFC</label>
                                                <InputText
                                                    id="rfc"
                                                    name="rfc"
                                                    /* className={values.rfc.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. BAI-020523-6Y8'
                                                    value={values.rfc.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                    </div >
                                    <Divider className='p-4' />
                                    <div className='grid'>

                                        <div className='col-12 md:col-12'>
                                            <h5>Dirección</h5>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Calle y número exterior</label>
                                                <InputText
                                                    id="calle"
                                                    name="calle"
                                                    /* className={values.calle.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. AV FRACC DEL RIO FRIO 419'
                                                    value={values.calle.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-2'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Número interior</label>
                                                <InputText
                                                    id="numeroInterior"
                                                    name="numeroInterior"
                                                    className="p-d-block"
                                                    keyfilter={'int'}
                                                    type="text"
                                                    placeholder=''
                                                    value={values.numeroInterior.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-2'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Piso</label>
                                                <InputText
                                                    id="piso"
                                                    name="piso"
                                                    className="p-d-block"
                                                    type="text"
                                                    placeholder=''
                                                    value={values.piso.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Colonia</label>
                                                <InputText
                                                    id="colonia"
                                                    name="colonia"
                                                    /* className={values.colonia.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. FRACCIONAMIENTO IND. DEL MORAL'
                                                    value={values.colonia.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Municipio o alcaldía</label>
                                                <InputText
                                                    id="municipio"
                                                    name="municipio"
                                                    /* className={values.municipio.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. IZTAPALAPA'
                                                    value={values.municipio.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Ciudad</label>
                                                <InputText
                                                    id="ciudad"
                                                    name="ciudad"
                                                    /* className={values.ciudad.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. Ciudad de México'
                                                    value={values.ciudad.value}
                                                    onChange={changeValuesHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Código Postal</label>
                                                <InputText
                                                    id="cp"
                                                    name="cp"
                                                    /* className={values.cp.valid ? "p-d-block" : "p-invalid block"} */
                                                    type="text"
                                                    placeholder='Ej. 90109'
                                                    value={values.cp.value}
                                                    onChange={changeValuesHandler}
                                                    keyfilter='int'
                                                    maxLength={5}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-12'>
                                            <div className="p-field-checkbox">
                                                <Checkbox inputId="city1" value="Chicago" checked={ver} onChange={e => noVer(e.checked)} />
                                                <label htmlFor="city1">Utilizar esta dirección para facturación</label>
                                            </div>
                                        </div>

                                    </div>

                                    <Divider className='p-4' />
                                    {
                                        ver ||
                                        <div className='grid'>

                                            <div className='col-12 md:col-12'>
                                                <h5>Dirección de facturación</h5>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Calle y número exterior</label>
                                                    <InputText
                                                        id="calle"
                                                        name="calle"
                                                        /* className={facturationValues.calle.valid ? "p-d-block" : "p-invalid block"} */
                                                        type="text"
                                                        placeholder='Ej. AV FRACC DEL RIO FRIO 419'
                                                        value={facturationValues.calle.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-2'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Número interior</label>
                                                    <InputText
                                                        id="numeroInterior"
                                                        name="numeroInterior"
                                                        className="p-d-block"
                                                        type="text"
                                                        placeholder=''
                                                        value={facturationValues.numeroInterior.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-2'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Piso</label>
                                                    <InputText
                                                        id="piso"
                                                        name="piso"
                                                        className="p-d-block"
                                                        type="text"
                                                        placeholder=''
                                                        value={facturationValues.piso.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Colonia</label>
                                                    <InputText
                                                        id="colonia"
                                                        name="colonia"
                                                        /* className={facturationValues.colonia.valid ? "p-d-block" : "p-invalid block"} */
                                                        type="text"
                                                        placeholder='Ej. FRACCIONAMIENTO IND. DEL MORAL'
                                                        value={facturationValues.colonia.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Municipio o alcaldía</label>
                                                    <InputText
                                                        id="municipio"
                                                        name="municipio"
                                                        /* className={facturationValues.municipio.valid ? "p-d-block" : "p-invalid block"} */
                                                        type="text"
                                                        placeholder='Ej. IZTAPALAPA'
                                                        value={facturationValues.municipio.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Ciudad</label>
                                                    <InputText
                                                        id="ciudad"
                                                        name="ciudad"
                                                        /* className={facturationValues.ciudad.valid ? "p-d-block" : "p-invalid block"} */
                                                        type="text"
                                                        placeholder='Ej. Ciudad de México'
                                                        value={facturationValues.ciudad.value}
                                                        onChange={changeFacturationValuesHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Código Postal</label>
                                                    <InputText
                                                        id="cp"
                                                        name="cp"
                                                        /* className={facturationValues.cp.valid ? "p-d-block" : "p-invalid block"} */
                                                        type="text"
                                                        placeholder='Ej. 90109'
                                                        value={facturationValues.cp.value}
                                                        onChange={changeFacturationValuesHandler}
                                                        keyfilter='int'
                                                        maxLength={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div >

                                <div className='card'>
                                    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <div>
                                            <h5>Representantes de la empresa</h5>
                                        </div>
                                        <div className='text-right'>
                                            <ButtonPrime label="Agregar otro" icon="pi pi-plus" iconPos="right" className="p-button-outlined" onClick={() => { addRepresentative() }} />
                                        </div>
                                    </div>
                                    {
                                        representatives.map((representante: any, index: any) => {
                                            if (!representante?.delete) {
                                                return (
                                                    <div className='py-5' key={index}>
                                                        <div className="grid flex md:align-items-center">
                                                            <div className='col-6'>
                                                                <h6 className='m-0'>Representante principal</h6>
                                                            </div>
                                                            <div className='col-6 text-right'>
                                                                {index != 0 &&
                                                                    <ButtonPrime icon="pi pi-times" className="p-button-rounded" tooltip="Eliminar representante" tooltipOptions={{ position: 'top' }} onClick={() => deleteRepresentative(index)} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='grid'>
                                                            <div className='col-12 md:col-4'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Nombre completo</label>
                                                                    <span className="p-input-icon-left">
                                                                        <i className="pi pi-user" />
                                                                        <InputText
                                                                            id="nombre"
                                                                            name="nombre"
                                                                            type="text"
                                                                            placeholder='Nombre y apellidos'
                                                                            /* className={representatives[index].nombre.valid ? "p-d-block" : "p-invalid block"} */
                                                                            value={representatives[index].nombre.value}
                                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-4'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Celular</label>
                                                                    <span className="p-input-icon-left">
                                                                        <i className="pi pi-mobile" />
                                                                        <InputMask
                                                                            id="telefono"
                                                                            name='telefono'
                                                                            mask="(999) 999 9999"
                                                                            /* className={representatives[index].telefono.valid ? "p-d-block" : "p-invalid block"} */
                                                                            value={representatives[index].telefono.value}
                                                                            unmask={true}
                                                                            placeholder="(__) ___-____"
                                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                                        ></InputMask>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-4'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Correo electrónico</label>
                                                                    <span className="p-input-icon-left">
                                                                        <i className="pi pi-envelope" />
                                                                        <InputText
                                                                            id="correo"
                                                                            name="correo"
                                                                            type="text"
                                                                            placeholder='micorreo@mail.com'
                                                                            className={representatives[index].correo.valid ? "p-d-block" : "p-invalid block"}
                                                                            value={representatives[index].correo.value}
                                                                            onChange={(e) => changeRepresentativesValues(e, index)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-4'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Puesto</label>
                                                                    <InputText
                                                                        id="puesto"
                                                                        name="puesto"
                                                                        type="text"
                                                                        placeholder='Dir. de producto'
                                                                        /* className={representatives[index].puesto.valid ? "p-d-block" : "p-invalid block"} */
                                                                        value={representatives[index].puesto.value}
                                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-4'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Área</label>
                                                                    <InputText
                                                                        id="area"
                                                                        name="area"
                                                                        type="text"
                                                                        placeholder='Crédito'
                                                                        /* className={representatives[index].area.valid ? "p-d-block" : "p-invalid block"} */
                                                                        value={representatives[index].area.value}
                                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-2'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Subárea</label>
                                                                    <InputText
                                                                        id="subArea"
                                                                        name="subArea"
                                                                        type="text"
                                                                        placeholder='producto'
                                                                        /* className={representatives[index].subArea.valid ? "p-d-block" : "p-invalid block"} */
                                                                        value={representatives[index].subArea.value}
                                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-2'>
                                                                <div className="p-field">
                                                                    <label htmlFor="username1" className="p-d-block">Número de empleado</label>
                                                                    <InputText
                                                                        id="numeroEmpleado"
                                                                        name="numeroEmpleado"
                                                                        type="text"
                                                                        placeholder='producto'
                                                                        /* className={representatives[index].numeroEmpleado.valid ? "p-d-block" : "p-invalid block"} */
                                                                        value={representatives[index].numeroEmpleado.value}
                                                                        onChange={(e) => changeRepresentativesValues(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }

                                        )
                                    }
                                    <Divider className='p-4' />
                                    <div className='text-center fond-gris'>
                                        <ButtonPrime label="Aregar otro representante" className="p-button-link p-button-raised" icon="pi pi-plus" iconPos="left" onClick={() => { addRepresentative() }} />
                                    </div>
                                </div>
                            </div >

                        </TabPanel >

                        <TabPanel header="Clic&Read" disabled={hideActionButtons}>
                            <div className='pt-2' style={{
                                maxHeight: `70vh`,
                                overflow: "scroll"
                            }}>
                                <div className='grid'>
                                    <div className='col-12 md:col-6'>
                                        <div className='fond-phone' style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h4>Vista previa</h4>
                                            <div className='text-center' style={{ borderRadius: 20, maxWidth: '30rem', alignSelf: 'center' }} >
                                                <div style={{ background: `${colorBackground}`, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 20 }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', background: `${colorBackground}`, width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 20 }}>
                                                        {/* {result ?
                                                            <img ref={imageRef} src={result} className="login-logo" alt="login-logo" style={{ marginTop: '3rem', marginBottom: '1rem' }} width={'40%'} /> :
                                                            <img src={`assets/layout/images/Algorithia-logoWhite.png`} className="login-logo" alt="login-logo" style={{ marginTop: '3rem', marginBottom: '1rem' }} width={'40%'} />
                                                        } */}
                                                        <div style={{ display: 'flex', justifyContent: 'center', background: `${colorBackground}`, width: '100%' }}>
                                                            <div style={{
                                                                display: 'flex', marginTop: '2rem', marginBottom: '2rem', marginRight: '2rem', marginLeft: '2rem', maxWidth: '80%', height: 'auto',
                                                                justifyContent: selectedAlign.name == 'Centrado' ? 'center' : selectedAlign.name == 'Centrado - Izquierda' ? 'left' : selectedAlign.name == 'Centrado - Derecha' ? 'right' : 'center'
                                                            }}>
                                                                {result ?
                                                                    <img ref={imageRef} src={result} className="login-logo" alt="login-logo" style={{ marginTop: '3rem', marginBottom: '1rem' }} width={'50%'} /> :
                                                                    <img src={`assets/layout/images/Algorithia-logoWhite.png`} className="login-logo" alt="login-logo" style={{ marginTop: '3rem', marginBottom: '1rem' }} width={'50%'} />
                                                                }

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: 16 }}>
                                                            <p style={{ fontSize: 24, fontWeight: 'bold', color: `${textColor}`, fontFamily: `${typo.name}` }}>¡Hola, qué tal!</p>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, background: '#fff', height: '100%', width: '100%' }}>

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 35, marginRight: 20, marginLeft: 20 }}>
                                                            <p style={{ fontSize: 14, color: '#45474D', fontWeight: 500, lineHeight: '20px', fontFamily: `${typo.name}` }}>Ingresa el número celular con el que te registraste e inicia sesión.</p>
                                                        </div>

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', marginTop: 33, width: '100%', maxWidth: '23rem' }}>
                                                            <p style={{ fontSize: 12, fontWeight: 'bold', color: 'black', fontFamily: `${typo.name}` }}>Celular a 10 dígitos</p>
                                                            <span className="p-input-icon-left" style={{ width: '100%' }}>
                                                                <i className="pi pi-mobile" style={{ color: `${colorBackground}` }}></i>
                                                                <InputText placeholder="(_ _ _) _ _ _   _ _ _ _" style={{ width: '100%', fontFamily: `${typo.name}` }} />
                                                            </span>
                                                        </div>

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 114, width: '90%' }}>
                                                            <ButtonPrime label='Siguiente' style={{ width: '100%', height: 52, maxWidth: '25rem', backgroundColor: `${buttonColor}`, borderColor: `${buttonColor}`, color: `${textButtonColor}`, fontFamily: `${typo.name}` }} />
                                                        </div>

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 56, marginBottom: 72, width: '100%', maxWidth: '25rem' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'row' }}>
                                                                <p style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '14px',
                                                                    lineHeight: '20px',
                                                                    color: '#212426',
                                                                    fontFamily: `${typo.name}`
                                                                }}>¿Aún no tienes cuenta?</p>
                                                                &nbsp;<p style={{ fontSize: 14, color: '#5c39e9' }}> </p>&nbsp;
                                                                <p style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '14px',
                                                                    lineHeight: '20px',
                                                                    color: `${colorBackground}`,
                                                                    fontFamily: `${typo.name}`
                                                                }} >Regístrate</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <h5>Colores</h5>
                                        <p>Elige los colores de acuerdo al cliente</p>
                                        <div className='grid' style={{ marginTop: 24 }}>
                                            <div className='col-12 md:col-6'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Color de fondo e íconos</label>
                                                    <div className='fond-gris flex  md:align-items-center' style={{ background: '#F7F7F7' }}>
                                                        <ColorPicker value={colorBackground} format="hex" onChange={(e) => {
                                                            setColorBackground(`#${e.value}`)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                        <InputText id="username1" className="p-d-block ml-5 p-inputtext-sm" type="text" placeholder='#00000' value={colorBackground} onChange={(e) => {
                                                            setColorBackground(e.target.value)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Texto sobre fondo de color</label>
                                                    <div className='fond-gris flex  md:align-items-center'>
                                                        <ColorPicker value={textColor} onChange={(e) => {
                                                            setTextColor(`#${e.value}`)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                        <InputText id="username1" className="p-d-block ml-5 p-inputtext-sm" type="text" placeholder='#00000' value={textColor} onChange={(e) => {
                                                            setTextColor(e.target.value)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Color de botones</label>
                                                    <div className='fond-gris flex  md:align-items-center'>
                                                        <ColorPicker value={buttonColor} onChange={(e) => {
                                                            setButtonColor(`#${e.value}`)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                        <InputText id="username1" className="p-d-block ml-5 p-inputtext-sm" type="text" placeholder='#00000' value={buttonColor} onChange={(e) => {
                                                            setButtonColor(e.target.value)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="p-field">
                                                    <label htmlFor="username1" className="p-d-block">Texto de botones</label>
                                                    <div className='fond-gris flex  md:align-items-center'>
                                                        <ColorPicker value={textButtonColor} onChange={(e) => {
                                                            setTextButtonColor(`#${e.value}`)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                        <InputText id="username1" className="p-d-block ml-5 p-inputtext-sm" type="text" placeholder='#00000' value={textButtonColor} onChange={(e) => {
                                                            setTextButtonColor(e.target.value)
                                                            setChangeMadeInData('CR')
                                                            setHideActionButtons(true)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 48 }}>
                                            <h5>Logotipo</h5>
                                            <p>Sube una imagen en formato .svg o .png sin fondo.</p>
                                            <div>
                                                {result ?
                                                    <div style={{ display: 'flex', width: 492, justifyContent: 'space-between', marginBottom: 24 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <i className="pi pi-file" style={{ color: '#613aeb', fontSize: '1.5em' }}></i>
                                                            <p style={{
                                                                marginLeft: 17,
                                                                fontWeight: '500',
                                                                fontSize: '16px',
                                                                lineHeight: '24px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: '#8A9196',
                                                            }}>{image?.name || 'Logotipo actual'}</p>
                                                        </div>
                                                        <div>
                                                            <i className="pi pi-times-circle" style={{ background: '#EDF0F2', borderRadius: 10, color: '#ABB5BD', fontSize: '1.5em' }} onClick={() => {
                                                                setImage('')
                                                                setResult()
                                                                setChangeMadeInData('CR')
                                                                setHideActionButtons(true)
                                                            }}></i>
                                                        </div>

                                                    </div>
                                                    :
                                                    <Dropzone onDrop={onDrop} accept={"image/*"} />
                                                }
                                            </div>
                                        </div>

                                        <div style={{ marginTop: 60 }}>
                                            <h5>Posición del logotipo</h5>
                                            <p>Elige la posición donde quieras que se muestre tu logo.</p>
                                            <Dropdown value={selectedAlign} options={alignLogo} onChange={onAlignLogoChange} optionLabel="name" placeholder="Posición" style={{ width: '50%' }} />
                                        </div>

                                        <div style={{ marginTop: 48 }}>
                                            <h5>Tipografía</h5>
                                            <p>Elige el estilo de fuente que más se asemeje a la del cliente.</p>
                                            <Dropdown value={typo} options={typography} onChange={onTypographyChange} optionLabel="name" placeholder="Tipografía" style={{ width: '50%' }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel header="Gestión de Datos" disabled={hideActionButtons}>
                            <div className='card'>
                                <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <div>
                                        <h5>Representantes receptor de información</h5>
                                    </div>
                                    <div className='text-right'>
                                        <ButtonPrime label="Agregar otro" icon="pi pi-plus" iconPos="right" className="p-button-outlined" onClick={() => { addReceivingRepresentative() }} />
                                    </div>
                                </div>
                                <div>
                                    <p className='pb-5'>Formato de archivo</p>
                                    <div className='grid pb-5'>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="e_pdf" name="e_pdf" checked={dataManagment.e_pdf} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="e_pdf">PDF</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="e_json" name="e_json" checked={dataManagment.e_json} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="e_json">JSON</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="e_xml" name="e_xml" checked={dataManagment.e_xml} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="e_xml">XML</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="e_csv" name="e_csv" checked={dataManagment.e_csv} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="e_csv">CSV</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="e_xls" name="e_xls" checked={dataManagment.e_xls} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="e_xls">XLSX</label>
                                        </div></div>
                                    </div>
                                    {
                                        receivingRepresentative.map((representante: any, index: any) => {
                                            if (!representante?.delete) {
                                                return (
                                                    <div className='grid' style={{ marginTop: 10 }} key={index}>
                                                        <div className='col-12 md:col-6'>
                                                            <div className="p-field">
                                                                <label htmlFor="username1" className="p-d-block">Celular</label>
                                                                <span className="p-input-icon-left">
                                                                    <i className="pi pi-mobile" />
                                                                    <InputMask
                                                                        name='telefono'
                                                                        mask="(999) 999 9999"
                                                                        className={receivingRepresentative[index].telefono?.valid ? "p-d-block" : "p-invalid block"}
                                                                        value={receivingRepresentative[index].telefono.value}
                                                                        unmask={true}
                                                                        placeholder="(__) ___-____"
                                                                        onChange={(e) => changeReceivingRepresentativeValues(e, index)}
                                                                    ></InputMask>

                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 md:col-6' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <div className="p-field" style={{ width: '93%' }}>
                                                                <label htmlFor="username1" className="p-d-block">Correo electrónico</label>
                                                                <span className="p-input-icon-left">
                                                                    <i className="pi pi-envelope" />
                                                                    <InputText
                                                                        name="correo"
                                                                        className={receivingRepresentative[index].correo?.valid ? "p-d-block" : "p-invalid block"}
                                                                        type="text"
                                                                        value={receivingRepresentative[index].correo.value}
                                                                        placeholder='micorreo@mail.com'
                                                                        onChange={(e) => changeReceivingRepresentativeValues(e, index)}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '5%' }}>
                                                                {index != 0 &&
                                                                    <ButtonPrime icon="pi pi-times" className="p-button-rounded" tooltip="Eliminar representante" tooltipOptions={{ position: 'top' }} onClick={() => deleteReceivingRepresentative(index)} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }

                                        )
                                    }

                                </div>
                                <Divider className='p-4' />
                                <div className='text-center fond-gris'>
                                    <ButtonPrime label="Agregar otro representante" className="p-button-link p-button-raised" icon="pi pi-plus" iconPos="left" onClick={() => {
                                        addReceivingRepresentative()
                                    }} />
                                </div>
                            </div>

                            <div className='card'>
                                <div className=''>
                                    <h5>Repositorio AWS S3</h5>
                                </div>
                                <div>
                                    <p className='pb-5'>Formato de archivo</p>
                                    <div className='grid pb-5'>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="a_pdf" name="a_pdf" checked={dataManagment.a_pdf} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="city1">PDF</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="a_json" name="a_json" checked={dataManagment.a_json} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="city1">JSON</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="a_xml" name="a_xml" checked={dataManagment.a_xml} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="city1">XML</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="a_csv" name="a_csv" checked={dataManagment.a_csv} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="city1">CSV</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="a_xls" name="a_xls" checked={dataManagment.a_xls} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="city1">XLSX</label>
                                        </div></div>
                                    </div>
                                    <Divider className='p-4' />
                                    <div className='col-12 md:col-12'>
                                        <div className="p-field">
                                            <label htmlFor="username1" className="p-d-block">Bucket Name</label>
                                            <InputText
                                                id="aws_bucket"
                                                name="aws_bucket"
                                                className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                type="text"
                                                placeholder='BucketName'
                                                value={dataManagment.aws_bucket}
                                                onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                            />
                                        </div>
                                    </div>
                                    <Divider className='p-4' />
                                    <div className='grid'>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Access Key</label>
                                                <Password
                                                    id="aws_key"
                                                    name="aws_key"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    placeholder='******************'
                                                    toggleMask
                                                    feedback={false}
                                                    value={dataManagment.aws_key}
                                                    onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                                />

                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Secret Key</label>
                                                <Password
                                                    id="aws_secret"
                                                    name="aws_secret"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    placeholder='******************'
                                                    toggleMask
                                                    feedback={false}
                                                    value={dataManagment.aws_secret}
                                                    onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">Region</label>
                                                <InputText
                                                    id="aws_region"
                                                    name="aws_region"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    type="text"
                                                    placeholder='US-WEST-1'
                                                    value={dataManagment.aws_region}
                                                    onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card'>
                                <div className=''>
                                    <h5>Webhook (API REST)</h5>
                                </div>
                                <div>
                                    <p className='pb-5'>Formato de archivo</p>
                                    <div className='grid pb-5'>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="w_pdf" name="w_pdf" checked={dataManagment.w_pdf} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="w_pdf">PDF</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="w_json" name="w_json" checked={dataManagment.w_json} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="w_json">JSON</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="w_xml" name="w_xml" checked={dataManagment.w_xml} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="w_xml">XML</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="w_csv" name="w_csv" checked={dataManagment.w_csv} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="w_csv">CSV</label>
                                        </div></div>
                                        <div className='col'>  <div className="p-field-checkbox">
                                            <Checkbox inputId="w_xls" name="w_xls" checked={dataManagment.w_xls} onChange={e => changeDataManagement(e.checked, e.target.name)} />
                                            <label htmlFor="w_xls">XLSX</label>
                                        </div></div>
                                    </div>

                                    <div className='col-12 md:col-12'>
                                        <div className="p-field">
                                            <label htmlFor="username1" className="p-d-block">URL</label>
                                            <InputText
                                                id="api_url"
                                                name="api_url"
                                                className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                type="text"
                                                placeholder='https://www.miweb.com'
                                                value={dataManagment.api_url}
                                                onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                            />
                                        </div>
                                    </div>
                                    <Divider className='p-4' />
                                    <div className='grid'>
                                        <div className='col-12 md:col-6'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">API Key</label>
                                                <Password
                                                    id="api_key"
                                                    name="api_key"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    placeholder='******************'
                                                    toggleMask
                                                    feedback={false}
                                                    value={dataManagment.api_key}
                                                    onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className="p-field">
                                                <label htmlFor="username1" className="p-d-block">UID</label>
                                                <Password
                                                    id="api_uid"
                                                    name="api_uid"
                                                    className={values.nombre.valid ? "p-d-block" : "p-invalid block"}
                                                    placeholder='******************'
                                                    toggleMask
                                                    feedback={false}
                                                    value={dataManagment.api_uid}
                                                    onChange={e => changeDataManagement(e.target.value, e.target.name)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>
                    </TabView >
                </div >

                {/*dialog para desactivar cliente*/}
                < Dialog header="" visible={displayBasic} style={{ width: 'auto' }} onHide={() => onHide()} footer={renderFooter()} >
                    <div className='text-center'>
                        <img src='../../assets/layout/images/desconectado.png' />
                        <h4 className='pb-3'>{`¿Quieres desactivar ${location?.state?.data?.nombre} ?`}</h4>
                    </div>
                </Dialog >
                {/*dialog para activar cliente*/}
                < Dialog header="" visible={displayBasic1} style={{ width: 'auto' }} onHide={() => onHide1()} footer={renderFooter1()} >
                    <div className='text-center'>
                        <img src='../../assets/layout/images/conectado.png' />
                        <h4 className='pb-3'>{`¿Quieres activar ${location?.state?.data?.nombre} ?`}</h4>
                    </div>
                </Dialog >
                {/*dialog para eliminar cliente*/}
                < Dialog header="" visible={displayBasic2} style={{ width: 'auto' }} onHide={() => onHide2()} footer={renderFooter2()} >
                    <div className='text-center'>
                        <img src='../../assets/layout/images/delete.png' />
                        <h4 className='pb-3'>{`¿Seguro que quieres eliminar ${location?.state?.data?.nombre}?`}</h4>
                        <p className='gris'>Esta acción ya no se puede deshacer</p>
                    </div>
                </Dialog >

                <Dialog header="" visible={modalQuit} style={{ width: 'auto' }} onHide={() => setModalQuit(false)}>
                    <div className='text-center'>
                        <img className="log-olv" src='../../assets/layout/images/warming.png' />
                        <h4 className='pb-3'>¿Deseas cancelar la edición del cliente?</h4>
                        <p className='pb-3'>Se perderán los datos ingresados.</p>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ButtonPrime type="button" label="Seguir editando" className='p-button-outlined mr-3' onClick={() => {
                                setModalQuit(false)
                            }} style={{ width: '50%' }} />
                            <ButtonPrime type="button" label="Salir" onClick={() => { returnToOriginal() }} style={{ width: '50%' }} />
                        </div>
                    </div>
                </Dialog>

                <Dialog header="" visible={displayBasic3} style={{ width: 'auto' }} onHide={() => setDisplayBasic3(false)}>
                    <div className='text-center'>
                        <img className="log-olv" src='../../assets/layout/images/check.png' />
                        <h4 className='pb-3'>Se guardaron los cambios exitosamente</h4>
                        <h4 className='pb-3'>¿Qué quieres hacer?</h4>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ButtonPrime type="button" label="Seguir Editando" onClick={() => {
                                setDisplayBasic3(false)
                            }} className='p-button-outlined mr-3' style={{ width: '50%' }} />
                            <ButtonPrime type="button" label="Finalizar" onClick={() => {
                                tokenServices.checkTokenTimeout()
                                history.push('/reportes')
                            }} style={{ width: '50%' }} />
                        </div>
                    </div>
                </Dialog>

            </section >
    );
}

