import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import authGuardService from '../service/authGuardService';
import tokenServices from '../service/token.service';
import UserService from '../service/user.service';

export const Login = (props: any) => {

	const [email, setEmail] = useState<string>('')
	const [userNameInvalid, setUserNameInvalid] = useState(false)
	const [emailNoValid, setEmailNoValid] = useState(false)
	const [password, setPassword] = useState<string>('')
	const [passwordInvalid, setPasswordInvalid] = useState(false)
	const toast = useRef<any>();
	const history = useHistory();
	const permisos: { label: any; }[] = []
	const [displayBasic, setDisplayBasic] = useState(false);

	const [loginButtonDisabled, setLoginButtonDisabled] = useState(true)

	const changeEmailValue = (value: string) => {
		setEmailNoValid(!isValidEmail(value))
		setUserNameInvalid(false)
		setEmail(value)
		checkButtonDisabled()
	}

	const changePassWordValue = (value: string) => {
		setPasswordInvalid(false)
		setPassword(value)
		checkButtonDisabled()
	}

	const checkButtonDisabled = () => {
		if (!emailNoValid && email && password.length > 3) setLoginButtonDisabled(false)
	}

	function isValidEmail(email: string) {
		return /\S+@\S+\.\S+/.test(email);
	}

	const isMobile = () => {
		return window.innerWidth <= 991;
	}

	const onHide = () => {
		setDisplayBasic(false)
	}
	const onClick = () => {
		if (email) {
			UserService.requestEmailForgotPassword(email).then(async res => {
				if (res.data.ok) {
					setDisplayBasic(true)
				} else {
					if (res.data.mensaje == 'Usuario no existe') setUserNameInvalid(true)
				}
			}).catch(e => {
				console.log('.')
			})
		} else {
			setUserNameInvalid(true)
		}
	}

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	const userLogin = () => {
		if (email.length <= 0 || password.length <= 0) {
			setUserNameInvalid(true)
			setPasswordInvalid(true)
		} else {
			let datos
			datos = email + ":" + password;
			const encodedString = Buffer.from(datos).toString('base64');
			UserService.loginUsuario(encodedString).then(async res => {
				const response: any = res;
				if (response.error == 'Unauthorized') {
					setUserNameInvalid(true)
					setPasswordInvalid(true)
				} else {
					authGuardService.sendKey(response.data.access_token)
					authGuardService.sendUser(response.data)
					tokenServices.setTokenTimeOut(res.data.expires_in)
					history.push("/reportes");
				}
			}).catch(error => {
				setPasswordInvalid(true)
				setUserNameInvalid(true)
			})
		}
	}

	return (
		<section>
			<div className="login-body">
				<Toast ref={toast} />

				<div className='grid'>
					<div className='col-12 md:col-6'>

						<div className='logo'>
							<img src='../../assets/layout/images/Algorithia-logoMorado.png' width={'15%'} />
						</div>
						<div className='login'>
							<h3 className='pb-5' style={{
								fontFamily: 'Montserrat',
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '28px',
								lineHeight: '125%',
								color: '#000000',
								marginBottom: 64
							}}>Inicia sesión</h3>

							<div className='grid' style={{ maxWidth: 500 }}>
								<div className='col-12 md:col-12'>
									<div className="p-field pb-3">
										<label
											htmlFor="username1"
											style={{
												fontFamily: 'Roboto',
												fontStyle: 'normal',
												fontWeight: 600,
												fontSize: '14px',
												lineHeight: '20px',
												color: ' #25303C',
												marginBottom: 4
											}}
										>Usuario</label>
										<InputText
											id="username1"
											className={userNameInvalid || emailNoValid ? "p-invalid block" : "p-d-block"}
											value={email}
											type="text"
											placeholder='Correo Electrónico'
											onChange={(e) => { changeEmailValue(e.target.value) }}
											style={{
												fontFamily: 'Roboto',
												fontStyle: 'normal',
												fontWeight: 400,
												fontSize: '17px',
												lineHeight: '24px',
												color: '#212426',
											}}
										/>
										{emailNoValid && <small id="username2-help" className="p-error block" style={{ marginTop: 4 }}><i className="pi pi-exclamation-circle mr-2"></i>Ingresa un correo válido</small>}
										{userNameInvalid && <small id="username2-help" className="p-error block" style={{ marginTop: 4 }}><i className="pi pi-exclamation-circle mr-2"></i>Este usuario no existe</small>}
									</div>
								</div>

								<div className='col-12 md:col-12'>
									<div className="p-field">
										<label htmlFor="username1" className="p-d-block "
											style={{
												fontFamily: 'Roboto',
												fontStyle: 'normal',
												fontWeight: 600,
												fontSize: '14px',
												lineHeight: '20px',
												color: ' #25303C',
												marginBottom: 4
											}}
										>Contraseña</label>
										<Password
											value={password}
											onChange={(e) => {
												changePassWordValue(e.target.value)
											}}
											placeholder="Contraseña"
											feedback={false}
											toggleMask
											className={passwordInvalid ? "p-invalid block" : "p-d-block"}
											style={{
												fontFamily: 'Roboto',
												fontStyle: 'normal',
												fontWeight: 400,
												fontSize: '17px',
												lineHeight: '24px',
												color: '#212426',

											}}
										/>
										{passwordInvalid && <small id="username2-help" className="p-error block" style={{ marginTop: 4 }}><i className="pi pi-exclamation-circle mr-2"></i>Contraseña incorrecta</small>}

									</div>
								</div>

								<div className='col-12 md:col-12 olvide'>
									<Button label="Olvidé mi contraseña" className="p-button-text" style={{
										marginTop: 18,
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: 400,
										fontSize: 12,
										lineHeight: '15px',
										marginLeft: -20,
										color: '#5C39E9',
										marginBottom: 40,
									}} onClick={() => onClick()}
									/>
								</div>

								<div className='col-12 md:col-12 olvide'>
									<Button type="button" label="Iniciar Sesión" onClick={userLogin} className="w-100" disabled={loginButtonDisabled}></Button>
								</div>
								<div className='col-12 md:col-12'>
									<p
										style={{
											fontFamily: 'Roboto',
											fontStyle: 'normal',
											fontWeight: '400',
											fontSize: '16px',
											lineHeight: '20px',
											color: '#000000',
											marginTop: 52
										}}
									>Si no tienes una cuenta, ponte en contacto con <a href="mailto:ventas@atd.com">ventas@atd.com</a> </p>
								</div>

							</div>
						</div>

					</div>
					{!isMobile() &&
						<div className='col-12 md:col-6'>
							<div className='size-fond'>
								<div className='fondo-login'>
									<div className='txt-fondo p-text-white txt-bottom'>
										<p className='text-right' onClick={() => window.open('tel:5512345678')}><i className="pi pi-phone"></i> +55 1234 5678</p>
										<div className=''>
											<h1 className='p-text-white ' style={{
												fontFamily: 'Poppins',
												fontStyle: 'normal',
												fontWeight: 600,
												fontSize: '40px',
												lineHeight: '60px',
												color: '#FFFFFF',
												marginLeft: 72,
												marginRight: 125
											}}>Potenciamos las relaciones entre personas y empresas</h1>
											<p style={{
												marginLeft: 72,
												marginRight: 125,
												fontFamily: 'Poppins',
												fontStyle: 'normal',
												fontWeight: 300,
												fontSize: '20px',
												lineHeight: '30px',
												color: '#FFFFFF'
											}}>Open Finance & Open Data</p>
										</div>

									</div>
								</div>
							</div>
							<div>

							</div>
						</div>
					}
				</div>
			</div>

			<Dialog header="" visible={displayBasic} style={{ width: '30vw' }} onHide={() => onHide()}>
				<div className='text-center'>
					<h4 className='pb-3' style={{
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '22px',
						lineHeight: '28px',
						textAlign: 'center',
						color: '#1F2937'
					}}>Mira en tu correo</h4>
					<p className='pb-3' style={{
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '16px',
						lineHeight: '28px',
						textAlign: 'center',
						color: '#1F2937',
						marginBottom: 24
					}}>Te envié un link de recuperación</p>
					<Button type="button" label="Entendido" onClick={() => { onHide() }}></Button>
				</div>
			</Dialog>

		</section >



	)
}
