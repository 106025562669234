import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import authGuardService from '../../service/authGuardService';
import tokenServices from '../../service/token.service';

const AppInlineMenu = (props: any) => {

    const [user, setUser] = useState<any>(null);


    useEffect(() => {
        setUser(authGuardService.getUser())
    }, []);

    const history = useHistory();

    const isSlim = () => {
        return props.menuMode === 'slim';
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    }

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    }

    const isMobile = () => {
        return window.innerWidth <= 991;
    }

    const logout = () => {
        authGuardService.logout()
        history.push("/");
    }

    const navigateToProfile = () => {
        tokenServices.checkTokenTimeout()
        history.push("/perfil")
    }

    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                    <Avatar icon="pi pi-user" className="mr-2" size="large" shape="circle" />
                    <span className="layout-inline-menu-text">{user?.name ? user.name : ''}</span>
                    <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                </button>
                <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                    <ul className="layout-inline-menu-action-panel" onClick={logout}>
                        <li className="layout-inline-menu-action-item">
                            <button className="p-link" onClick={navigateToProfile}>
                                <i className="pi pi-power-off pi-fw"></i>
                                <span>Cerrar Sesión</span>
                            </button>
                        </li>
                        <li className="layout-inline-menu-action-item">
                            <button className="p-link">
                                <i className="pi pi-user pi-fw"></i>
                                <span>Perfil</span>
                            </button>
                        </li>
                    </ul>
                </CSSTransition>
            </div>}
        </>
    )
}

export default AppInlineMenu;