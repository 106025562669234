import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import './App.scss';
import AppTopbar from './Components/TopBar';
import AppMenu from './Components/VerticalMenu/AppMenu';
import configFile from './configurationFile';
import Dashboard from './pages/Dashboard';
import authGuardService from './service/authGuardService';

import CatalogExample2 from './pages/clientes/CatalogExample2';
import { EditClient } from './pages/clientes/EditClient';
import { NewClient } from './pages/clientes/NewClient';
import tokenServices from './service/token.service';


const App = (props: any) => {

    const userData = authGuardService.getUser()
    const [menuMode, setMenuMode] = useState(configFile.menu);

    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);

    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    /* const token = authGuardService.getToken()
    const user = authGuardService.getUser() */
    const toast = useRef<any>();
    const permisos: { label: any; }[] = []

    PrimeReact.ripple = true;

    const isAuthenticated = (path: any) => {
        const permissions = authGuardService.getPermits()

        let check = false
        permissions?.forEach((element: any) => {
            if (path.toLowerCase() == element.label.toLowerCase()) check = true;
        });
        return check;
    }

    const menu = [
        /* {
            label: 'Inicio', icon: 'pi pi-home', to: '/home'
        }, */
        {
            label: "Gestión comercial", icon: "pi pi-building", to: "/reportes"
        },

        //{
        //    label: 'Catalogos', icon: 'pi pi-folder',
        //    items: [
        //        isAuthenticated('settings') && { label: 'Settings', icon: 'pi pi-cog', to: '/settings' },
        //        isAuthenticated('catejemplo') && { label: 'Cat Ejemplos', icon: 'pi pi-check-square', to: '/cat-ejemplo' }
        //    ]
        //},

        //isAuthenticated('accesos') && {
        //    label: 'Accesos', icon: 'pi pi-cog', to: '/accesos/usuarios'
        //},


    ];



    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(()=>{
        tokenServices.checkTokenTimeout()
    },[])

    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)
    }, [menuMode])

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
                setResetActiveIndex(true)
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false)
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false)
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    }

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    }

    //Cambiar la posicion del menu
    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    }

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true)
    }

    const onRightMenuClick = () => {
        rightMenuClick = true;
    }

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    }

    const onConfigClick = () => {
        configClick = true;
    }

    const onConfigButtonClick = (event: any) => {
        setConfigActive(prevState => !prevState)
        configClick = true;
        event.preventDefault();
    }

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive(prevState => !prevState)
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevState => !prevState)
        } else {
            setStaticMenuMobileActive(prevState => !prevState)
        }

        event.preventDefault();
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false)
        }

        if (sidebarActive) {
            setSidebarStatic(prevState => !prevState)
        }

        event.preventDefault();
    }

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop())
            }, 200);
        }
    }

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    }

    const onMenuClick = () => {
        menuClick = true;
    }

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile(prevState => !prevState);
        event.preventDefault();
    }

    const onRootMenuItemClick = () => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const isOverlay = () => {
        return menuMode === 'overlay';
    }

    const isDesktop = () => {
        return window.innerWidth > 991;
    }


    const onInputClick = () => {
        searchClick = true
    }

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            {/* Menu cuando esta en modo horizontal */}
            <AppTopbar items={menu} menuMode={menuMode} colorScheme={props.colorScheme} menuActive={menuActive}
                topbarMenuActive={topbarMenuActive} activeInlineProfile={activeInlineProfile} onTopbarItemClick={onTopbarItemClick} onMenuButtonClick={onMenuButtonClick}
                onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave} onToggleMenu={onToggleMenu}
                onChangeActiveInlineMenu={onChangeActiveInlineMenu} onMenuClick={onMenuClick} onMenuItemClick={onMenuItemClick}
                onRootMenuItemClick={onRootMenuItemClick} resetActiveIndex={resetActiveIndex} />

            <div className="layout-main">

                {/* Menu cuando esta en modo lateral */}
                <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick} onToggleMenu={onToggleMenu} onMenuClick={onMenuClick} menuMode={menuMode}
                    colorScheme={props.colorScheme} menuActive={menuActive}
                    sidebarActive={sidebarActive} sidebarStatic={sidebarStatic} pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave} onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile} onChangeActiveInlineMenu={onChangeActiveInlineMenu} resetActiveIndex={resetActiveIndex} />


                <div className="layout-main-content">
                    {/* <Route path="/home" exact component={Dashboard} /> */}

                    <Route
                        path="/reportes"
                        render={() =>
                            <CatalogExample2 />
                        } />

                    <Route
                        path="/newclient"
                        render={() =>
                            <NewClient />
                        } />

                    <Route
                        path="/EditClient"
                        render={() =>
                            <EditClient />
                        } />

                    <Route exact path="/">
                        <Redirect to="/reportes" />
                    </Route>
                    
                    <Route exact path="/*">
                        <Redirect to="/reportes" />
                    </Route>
                </div>

            </div>

            {/* Boton de Configuracion de temas */}
            {/* <AppConfig configActive={configActive} onConfigButtonClick={onConfigButtonClick} onConfigClick={onConfigClick} menuMode={menuMode} changeMenuMode={onMenuModeChange}
                colorScheme={props.colorScheme} changeColorScheme={props.onColorSchemeChange} theme={props.theme} changeTheme={props.onMenuThemeChange}
                componentTheme={props.componentTheme} changeComponentTheme={props.onComponentThemeChange}
                ripple={ripple} onRippleChange={onRippleChange} /> */}

        </div>
    );

}

export default App;
