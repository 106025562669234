import { useDropzone } from "react-dropzone";

const Dropzone = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <div className="dropzone-div" {...getRootProps()} style={{
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '32px 56px',
      gap: '12px',
      background: '#F9FAFB',
      border: '1px dashed #9CA3AF',
      borderRadius: '4px',
      width: '304px',
      height: '80px',
      marginTop: 14,
      marginBottom: 48
    }}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p className="dropzone-content" style={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            textDecorationLine: 'underline',
            color: '#60A5FA'
          }}>
            Buscar
          </p>
          <p className="dropzone-content" style={{

            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '12px',

            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',

            color: '#4B5563'
          }}>
            O arrastre y suelte para cargar
          </p>
        </div>
      </div>
    </div >
  );
};

export default Dropzone;