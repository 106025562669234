import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import authGuardService from '../../service/authGuardService';
import UserService from '../../service/user.service';

export const ActivContra = (props: any) => {

    const history = useHistory();
    const user = authGuardService.getUser()
    const [password, setPassword] = useState<string>('');
    const [passwordValid, setPasswordValid] = useState<boolean>(true)
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordValid, setConfirmPasswordValid] = useState<boolean>(true)
    const [passwordsEqual, setPasswordEqual] = useState<boolean>(true);
    const [noReq, setNoReq] = useState<boolean>(false)

    function containsUppercase(str: string) {
        return /[A-Z]/.test(str);
    }

    function containsNumbers(str: string) {
        return /\d/.test(str);
    }

    function containsAtLeastEight(str: string) {
        return str.length > 7
    }

    const changePassword = () => {
        let usuId = props.location.pathname
        setPasswordValid(password.length > 8)
        setConfirmPasswordValid(confirmPassword.length > 8)
        setPasswordEqual(password == confirmPassword)

        usuId = usuId.replace('/activcontra/ajs', '')
        usuId = usuId.replace('hdlwos', '')
        usuId = parseInt(usuId)

        if (
            password == confirmPassword &&
            containsUppercase(password) &&
            containsNumbers(password) &&
            containsAtLeastEight(password) &&
            Number.isInteger(usuId)
        ) {
            UserService.requestLoginDev().then(async resLogin => {
                UserService.changePassword(usuId, password, resLogin.data.access_token).then(async res => {
                    if (res.data.ok) {
                        authGuardService.logout()
                        history.push("/");
                    } else {
                        console.log('.')
                    }

                }).catch(e => {
                    console.log('.')
                })
            })
        } else {
            setNoReq(true)
        }
    }

    return (
        <section>
            <div className='flex justify-content-between olvide'>
                <div className="">
                    <img className="log-olv" src='../../assets/layout/images/Algorithia-logoMorado.png' />
                </div>
                <div className="flex align-items-center">
                    <p style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '15px',
                        lineHeight: '22px',
                        color: '#5C39E9'
                    }}><i className="pi pi-phone"></i> +55 1234 5678</p>
                </div>

            </div>
            <div className="flex justify-content-center flex-wrap" >
                <div className="p-grid" >
                    <div className='col-12 md:col-12'>
                        <div className='text-center'>
                            <img src='../../assets/layout/images/lock.png' alt="" />
                        </div>
                        <p style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '28px',
                            lineHeight: '125%',
                            color: '#000000',
                            marginTop: 44
                        }}>Nueva contraseña</p>
                        <p style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '125%',
                            color: '#000000',
                            marginTop: 27
                        }}>Crea una contraseña fácil de recordar</p>
                    </div>
                    <div className='col-12 md:col-12'>
                        <div className="p-field pb-3">
                            <label style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#25303C',
                                marginTop: 56
                            }}>Contraseña</label>
                            <Password
                                value={password}
                                placeholder="Contraseña"
                                feedback={false}
                                toggleMask
                                className={!passwordValid ? "p-invalid block" : ''}
                                onChange={(e) => {
                                    setPasswordValid(e.target.value.length > 8)
                                    setPassword(e.target.value)
                                    setPasswordEqual(e.target.value == confirmPassword)
                                    setNoReq(false)
                                }}
                            />
                            {!passwordValid && <small id="username2-help" className="p-error block" style={{ marginTop: 4, marginBottom: 10 }}><i className="pi pi-exclamation-circle mr-2"></i>Contraseña incorrecta</small>}
                            {!passwordsEqual && <small id="username2-help" className="p-error block" style={{ marginTop: 4, marginBottom: 10 }}><i className="pi pi-exclamation-circle mr-2"></i>Las Contraseñas no coinciden.</small>}
                            {noReq && <small id="username2-help" className="p-error block" style={{ marginTop: 4, marginBottom: 10 }}><i className="pi pi-exclamation-circle mr-2"></i>La Contraseña no cumple con los requisitos marcados</small>}

                            <small style={{
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#666E70',
                                marginTop: 8
                            }}>
                                La contraseña debe incluir: <br />
                                <span className='verde'>
                                    <i className="pi pi-check"></i>  8 caracteres mínimo <br />
                                    <i className="pi pi-check"></i> Al menos una letra Mayúscula <br />
                                    <i className="pi pi-check"></i> Al menos un número</span> <br />
                            </small>
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <div className="p-field">
                            <label style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#25303C'
                            }}>Confirmar contraseña</label>
                            <Password
                                value={confirmPassword}
                                placeholder="Contraseña"
                                feedback={false}
                                toggleMask
                                className={!confirmPasswordValid ? "p-invalid block" : ''}
                                onChange={(e) => {
                                    setConfirmPasswordValid(e.target.value.length > 8)
                                    setConfirmPassword(e.target.value)
                                    setPasswordEqual(password == e.target.value)
                                    setNoReq(false)
                                }}
                            />
                            {!confirmPasswordValid && <small id="username2-help" className="p-error block" style={{ marginTop: 4, marginBottom: 10 }}><i className="pi pi-exclamation-circle mr-2"></i>Contraseña incorrecta</small>}
                            {!passwordsEqual && <small id="username2-help" className="p-error block" style={{ marginTop: 4, marginBottom: 10 }}><i className="pi pi-exclamation-circle mr-2"></i>Las Contraseñas no coinciden.</small>}

                        </div>
                    </div>
                    <div className='col-12 md:col-12 pt-5'>
                        <Button type="button" label="Guardar contraseña" className="w-100" onClick={() => { changePassword() }}></Button>
                    </div>
                </div>

            </div>
        </section >
    )
}