import { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import App from '../src/App';
import { Access } from '../src/pages/AccessDenied';
import { Login } from '../src/pages/Login';
import authGuardService from '../src/service/authGuardService';
import configFile from './configurationFile';

import { ActivContra } from './pages/contraseñas/ActivContra';
import { OlvContra } from './pages/contraseñas/OlvContra';

const AppWrapper = (props: any) => {
	const [colorScheme, setColorScheme] = useState(configFile.theme)
	const [theme, setTheme] = useState('green');
	const [componentTheme, setComponentTheme] = useState('green');
	const [permissions, setPermissions] = useState<any>(null);

	const history = useHistory();
	/* const token = authGuardService.getToken()
	const user = authGuardService.getUser() */
	const toast = useRef<any>();
	const permisos: { label: any; }[] = []

	let location = useLocation();

	var regexActivatePassword = new RegExp(/^\/activcontra\/ajs[0-9]+hdlwos$/i)

	useEffect(() => {
		onColorSchemeChange(colorScheme);
		onComponentThemeChange('green')
	}, []);

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	const onColorSchemeChange = (scheme: string) => {
		changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
		changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
		setColorScheme(scheme);
	}

	const changeStyleSheetUrl = (id: any, value: any, from: any) => {
		const element = (document.getElementById(id) as HTMLInputElement);
		const urlTokens = (element.getAttribute('href') as String).split('/');

		if (from === 1) {           // which function invoked this function - change scheme
			urlTokens[urlTokens.length - 1] = value;
		} else if (from === 2) {       // which function invoked this function - change color
			urlTokens[urlTokens.length - 2] = value;
		}

		const newURL = urlTokens.join('/');

		replaceLink(element, newURL);
	}

	const onMenuThemeChange = (theme: string) => {
		const layoutLink = document.getElementById('layout-css');
		const href = 'assets/layout/css/' + theme + '/layout-' + colorScheme + '.css';

		replaceLink(layoutLink, href);
		setTheme(theme);
	}

	const onComponentThemeChange = (theme: string) => {
		const themeLink = document.getElementById('theme-css');
		const href = 'assets/theme/' + theme + '/theme-' + colorScheme + '.css';
		replaceLink(themeLink, href);
		setComponentTheme(theme);
	}

	const replaceLink = (linkElement: any, href: string, callback?: any) => {
		const id = linkElement.getAttribute('id');
		const cloneLinkElement = linkElement.cloneNode(true);
		cloneLinkElement.setAttribute('href', href);
		cloneLinkElement.setAttribute('id', id + '-clone');
		linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
		cloneLinkElement.addEventListener('load', () => {
			linkElement.remove();
			cloneLinkElement.setAttribute('id', id);

			if (callback) {
				callback();
			}
		});
	}

	const home = () => {
		return <App colorScheme={colorScheme} onColorSchemeChange={onColorSchemeChange}
			componentTheme={componentTheme} onComponentThemeChange={onComponentThemeChange}
			theme={theme} onMenuThemeChange={onMenuThemeChange} state={props.location.state} />;
	}

	const ValidateHomeLogin = () => {
		if (authGuardService.isLoggednIn()) return home()
		return <Access colorScheme={colorScheme} state={props.location.state} />;
	};



	const ValidateLoggin = () => {
		if (authGuardService.isLoggednIn()) return home()
		return <Login colorScheme={colorScheme} state={props.location.state} />;
	}

	switch (props.location.pathname) {
		default: {
			if (regexActivatePassword.test(props.location.pathname)) return <Route path="/activcontra/:id" render={(props) => <ActivContra colorScheme={colorScheme} state={props.location.state} {...props} />} />
			else return ValidateLoggin()
		}
		case '/login':
			return ValidateLoggin()

		//NUEVAS VISTAS publicas
		case '/olvcontra':
			return <Route path="/olvcontra" render={() => <OlvContra colorScheme={colorScheme} state={props.location.state} />} />

		//vista privada
		case '/home':
			return <Route path="/Home" render={() => ValidateHomeLogin()} />

	}
}

export default withRouter(AppWrapper);