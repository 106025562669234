import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import { Button as ButtonPrime } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import authGuardService from '../../service/authGuardService';
import UserService from '../../service/user.service';

import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import tokenServices from '../../service/token.service';

const Crud = () => {
    const op: any = useRef(null);
    const history = useHistory();
    const toast = useRef<any>(null);
    const dt = useRef<any>(null);
    const token = authGuardService.getToken()

    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState<any>(null);
    const [productDialog, setProductDialog] = useState<boolean>(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState<boolean>(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState<boolean>(false);
    const [product, setProduct] = useState<any>(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic1, setDisplayBasic1] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>('');

    useEffect(() => {
        requestUsers()
    }, []);

    const onHide = () => {
        setDisplayBasic(false)
    }
    const onClick = (rowData: any) => {
        setDisplayBasic(true)
    }
    const onClick1 = () => {
        setDisplayBasic1(true)
    }
    const onHide1 = () => {
        setDisplayBasic1(false)
    }
    const onClick2 = () => {
        setDisplayBasic2(true)
    }
    const onHide2 = () => {
        setDisplayBasic2(false)
    }
    const renderFooter = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => onHide()} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, desactivar" onClick={() => { requestDeactivateClient() }} autoFocus className='p-button-danger' />
            </div>
        );
    }
    const renderFooter1 = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => onHide1()} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, activar" onClick={() => requestDeactivateClient()} autoFocus />
            </div>
        );
    }
    const renderFooter2 = () => {
        return (
            <div>
                <ButtonPrime label="Cancelar" onClick={() => onHide2()} className="p-button-text mr-2" />
                <ButtonPrime label="Sí, eliminar" onClick={() => requestDelete()} autoFocus className='p-button-danger' />
            </div>
        );
    }
    const requestUsers = () => {
        const token = authGuardService.getToken()
        UserService.getAllUsers(token).then(data => {
            setProducts(data.data._embedded.clientes)
        });
    }
    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }
    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                <div onClick={() => {
                    tokenServices.checkTokenTimeout()
                    history.push({
                        pathname: '/editClient',
                        state: { data: rowData }
                    })
                }}>
                    <span className='td-tit'>{rowData.nombre}</span>   <br />
                    <small> ID {rowData.cliId}</small>
                </div>
            </>
        );
    }
    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                <span className='td-tit'> {rowData.modificacion}</span> <br />
                <small> {rowData.nombre}</small>
            </>
        );
    }
    const statusItemTemplate = (rowData: any) => {
        <span className={`customer-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>
    }

    const getColorBackground = (value: string) => {
        if (value == 'PROSPECTO') return '#EDF0F2'
        else if (value == 'COTIZACIÓN') return 'rgba(165, 184, 247, 0.2)'
        else if (value == 'NEGOCIACIÓN') return 'rgba(177, 175, 252, 0.2)'
        else if (value == 'CONTRATO') return 'rgba(49, 213, 131, 0.2)'
        else if (value == 'DESCARTADO') return 'rgba(247, 165, 176, 0.2)'
        else return '#EDF0F2'
    }

    const getColorText = (value: string) => {
        if (value == 'PROSPECTO') return '#666E70'
        else if (value == 'COTIZACIÓN') return '#2E73F2'
        else if (value == 'NEGOCIACIÓN') return '#5450DF'
        else if (value == 'CONTRATO') return '#1E9459'
        else if (value == 'DESCARTADO') return '#D82953'
        else return '#666E70'
    }

    const [selectedStatus, setSelectedStatus] = useState(null);

    const statuss = [
        { name: 'PROSPECTO', code: 'PROSPECTO' },
        { name: 'COTIZACIÓN', code: 'COTIZACIÓN' },
        { name: 'NEGOCIACIÓN', code: 'NEGOCIACIÓN' },
        { name: 'CONTRATO', code: 'CONTRATO' },
        { name: 'DESCARTADO', code: 'DESCARTADO' },
    ];
    const selectedStatusTemplate = (value: string) => {
        const colorText = getColorText(value)
        return (
            <p style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'flex-end',
                textTransform: 'uppercase',
                color: `${colorText}`
            }}>
                {value}
            </p >
        );
    }

    const statusOptionTemplate = (option: any) => {
        const colorBackground = getColorBackground(option.name)
        const textColor = getColorText(option.name)

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px 4px 8px 8px',
                gap: '8px',
                width: '12rem',
                background: `${colorBackground}`,
                borderRadius: '45.5546px'
            }}
            >
                <p style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    textTransform: 'uppercase',
                    color: `${textColor}`
                }}>{option.name}</p>
            </div>
        );
    }
    const requestChangeStatus = (valueSelected: any, rowData: any) => {
        const newRowData = rowData;
        newRowData.status = valueSelected.name
        UserService.editUser(rowData.cliId, token, newRowData).then(data => {
            if (data.status == 200) {
                requestUsers()
            } else {
                /* setButtonLoading(false)
                notify() */
                console.log('.')
            }
        });
    }


    const statusBodyTemplate = (rowData: any) => {
        const dropDownColor = getColorBackground(rowData.status)
        return (
            <Dropdown
                value={rowData.status}
                options={statuss}
                onChange={(e) => requestChangeStatus(e.value, rowData)}
                optionLabel="status"
                placeholder="Selecciona un Status"
                valueTemplate={() => selectedStatusTemplate(rowData.status)}
                itemTemplate={statusOptionTemplate}
                scrollHeight={'100%'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px 4px 8px 8px',
                    gap: '8px',
                    width: '13rem',
                    height: '36px',
                    background: `${dropDownColor}`,
                    borderRadius: '45.5546px'
                }
                }
            />
        );
    }
    const categoryBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Clic&Read</span>
                <ButtonPrime label={`http://clickread.administradordemo.info/${rowData.cliId}`} className="p-button-link p-button-raised w-100" onClick={() => { openInNewTab(`http://clickread.administradordemo.info/${rowData.cliId}`) }} />
            </>
        );
    }

    const openInNewTab = (url: any) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const deactivateClient = (rowData: any) => {
        setSelectedItem(rowData)
        if (rowData.activo) {
            setDisplayBasic(true)
        } else {
            setDisplayBasic1(true)
        }
    }
    const requestDeactivateClient = () => {
        setDisplayBasic(false)
        setDisplayBasic1(false)
        setDisplayBasic2(false)
        const newData = selectedItem;
        const token = authGuardService.getToken()
        if (newData.activo) {
            newData.activo = !newData.activo
            UserService.activateUser(newData.cliId, newData, token).then(data => {
                setDisplayBasic(false)
                setDisplayBasic1(false)
                setDisplayBasic2(false)
            });
        } else {
            newData.activo = !newData.activo
            UserService.activateUser(newData.cliId, newData, token).then(data => {
                setDisplayBasic(false)
                setDisplayBasic1(false)
                setDisplayBasic2(false)
            });
        }

    }
    const eliminateClient = (rowData: any) => {
        setSelectedItem(rowData)
        setDisplayBasic2(true)
    }
    const requestDelete = async () => {
        const token = authGuardService.getToken()

        await UserService.getDesign(selectedItem.cliId, token).then(async response => {
            if (response.data._embedded.disenios.length > 0) {
                response.data._embedded.disenios.map(async (singleDesign: { disId: any; }) => {
                    await UserService.deleteDesign(singleDesign.disId, token)
                });
            }
        })

        await UserService.getRepresentantes(token).then(representantesResponse => {
            if (representantesResponse.data._embedded.representantes.length > 0) {
                representantesResponse.data._embedded.representantes.map(async (singleRepresentante: any) => {
                    if (singleRepresentante.cliId == selectedItem.cliId) {
                        await UserService.deleteRepresentante(singleRepresentante.repId, token)
                    }
                })
            }
        })

        await UserService.deleteUser(selectedItem.cliId, token).then(data => {
            setDisplayBasic2(false)
            requestUsers()
        });
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                    <Button
                        {...props}
                        iconBefore={<MoreVerticalIcon label="more" />}
                        ref={triggerRef}
                    />
                )}
            >
                <DropdownItemGroup>
                    <DropdownItem>
                        <div>
                            <div style={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: '4px',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                            }}>
                                <InputSwitch checked={rowData ? rowData.activo : false} onChange={() => { deactivateClient(rowData) }} />
                                <p style={{
                                    marginLeft: 16,
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    color: '#45474D',
                                }}>{rowData.activo ? 'Desactivar' : 'Activar'} Cliente</p>
                            </div>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                            <div style={{
                                marginBottom: 10,
                                marginLeft: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: '4px',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                            }}
                                onClick={() => { eliminateClient(rowData) }}
                            >
                                <i className="pi pi-trash" style={{ 'fontSize': '1.5em', color: 'red' }}></i>
                                <p style={{
                                    marginLeft: 25,
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    color: '#45474D',
                                }}>Eliminar Cliente</p>
                            </div>
                        </div >
                    </DropdownItem>
                </DropdownItemGroup>
            </DropdownMenu>
        );
    }

    return (
        <section className="sect-body" style={{ height: '100%' }}>
            <Toast ref={toast} />

            <div className='sect-content'>
                {/*seccion con tabla registros guardados*/}
                {
                    products && products.length > 0 &&
                    <div>
                        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center header-tab">
                            <div>
                                <h4 className="">Clientes</h4>
                            </div>
                            <div className='grid'>
                                <span className="p-input-icon-left pr-5">
                                    <i className="pi pi-search" />
                                    <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Buscador" />
                                </span>
                                <Link to="/newclient"><ButtonPrime label="Nuevo cliente" icon="pi pi-plus-circle" iconPos="right" className="" /></Link>

                            </div>
                        </div>
                        <Divider />

                        <div className='header-tab'>
                            <DataTable ref={dt} value={products} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                dataKey="id" paginator rows={10} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={globalFilter} emptyMessage="No products found." responsiveLayout="scroll">

                                <Column field="nombre" header="Cliente" sortable body={codeBodyTemplate} ></Column>
                                <Column field="modificacion" header="Última modificación" sortable body={nameBodyTemplate} ></Column>
                                <Column field="status" header="Status" body={statusBodyTemplate} sortable ></Column>
                                <Column field="link" header="Clic&Read" sortable body={categoryBodyTemplate} ></Column>
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                }

                {/*seccion cuando no hay ningun registro*/}
                {!products || products.length == 0 &&
                    <div className="flex justify-content-center flex-wrap p-5" style={{ height: '100%' }} >
                        <div className="flex align-items-center justify-content-center p-5">
                            <div className='text-center'>
                                <img src='../../assets/layout/images/add.png' />
                                <h4>Aún no hay clientes que mostrar</h4>
                                <p>Agrégalos para personalizar el Clic&Read con los colores y logo de cada uno.</p>
                                <Link to="/newclient"><ButtonPrime label="Nuevo cliente" icon="pi pi-plus-circle" iconPos="right" className="" /></Link>
                            </div>
                        </div>
                    </div>
                }

            </div>

            {/*dialog para desactivar cliente*/}
            <Dialog header="" visible={displayBasic} style={{ width: 'auto' }} onHide={() => onHide()} footer={renderFooter()}>
                <div className='text-center'>
                    <img src='../../assets/layout/images/desconectado.png' />
                    <h4 className='pb-3'>{`¿Quieres desactivar ${selectedItem.nombre} ?`}</h4>
                </div>
            </Dialog>

            {/*dialog para activar cliente*/}
            <Dialog header="" visible={displayBasic1} style={{ width: 'auto' }} onHide={() => onHide1()} footer={renderFooter1()}>
                <div className='text-center'>
                    <img src='../../assets/layout/images/conectado.png' />
                    <h4 className='pb-3'>{`¿Quieres activar ${selectedItem.nombre} ?`}</h4>
                </div>
            </Dialog>

            {/*dialog para eliminar cliente*/}
            <Dialog header="" visible={displayBasic2} style={{ width: 'auto' }} onHide={() => onHide2()} footer={renderFooter2()}>
                <div className='text-center'>
                    <img src='../../assets/layout/images/delete.png' />
                    <h4 className='pb-3'>{`¿Seguro que quieres eliminar ${selectedItem.nombre}?`}</h4>
                    <p className='gris'>Esta acción ya no se puede deshacer</p>
                </div>
            </Dialog>

        </section >
    );
}

export default React.memo(Crud);
