import Axios from 'axios';
import { RequestService } from '../utilities/request.component';
import EncryptService from './encrypt.service';

const API_URL = process.env.REACT_APP_API_URL

export default class UserService {


  static async loginUsuario(userKeys: any) {
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/auth-service/auth/login`,
      withCredentials: false,
      headers: {
        'Authorization': `Basic ${userKeys}`,
      }
    };

    return Axios(config)
  }

  static getAllUsers(token: string) {
    var data = '';
    var config: any = {
      method: 'get',
      url: `${API_URL}/api/backend-service/data/cliente`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    return Axios(config);
  }

  static agregaUsuario(json: any, token: string) {
    var data = JSON.stringify(json);
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/backend-service/data/cliente`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    return Axios(config)
  }

  static changePassword(id: any, pwd: string, token: string) {
    var data = JSON.stringify({
      "usu_id": id,
      "pwd": pwd
    });
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/auth-service/auth/cambiar_psw`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    return Axios(config)
  }

  static activateUser(id: any, json: any, token: string) {
    var data = JSON.stringify(json);
    var config: any = {
      method: 'put',
      url: `${API_URL}/api/backend-service/data/cliente/${id}`,
      headers: {
        'Authorization': `Bearer ${token}}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    return Axios(config)
  }

  static deleteUser(id: any, token: string) {
    var config: any = {
      method: 'delete',
      url: `${API_URL}/api/backend-service/data/cliente/${id}`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    return Axios(config)

  }

  static editUser(id: any, token: string, data: any) {
    var config: any = {
      method: 'put',
      url: `${API_URL}/api/backend-service/data/cliente/${id}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    };
    return Axios(config)
  }

  static addRepresentative(token: string, data: any) {
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/backend-service/data/representante`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    };

    return Axios(config)
  }

  static addDesign(design: any, cliId: any, token: any) {
    var data = JSON.stringify({
      "cliId": cliId,
      "fondo": design.fondo,
      "btn": design.btn,
      "txt": design.txt,
      "txtbtn": design.txtbtn,
      "logo": design.logo,
      "tipogr": design.tipogr,
    });

    var config: any = {
      method: 'post',
      url: `${API_URL}/api/backend-service/data/disenio`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    };

    return Axios(config)

  }

  static updateDesign(design: any, token: any, disId: any) {
    var data = JSON.stringify(design);

    var config: any = {
      method: 'put',
      url: `${API_URL}/api/backend-service/data/disenio/${disId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    };

    return Axios(config)
  }

  static getDesign(cliId: any, token: any) {
    var config: any = {
      method: 'get',
      url: `${API_URL}/api/backend-service/data/disenio/search/findByCliId?cliId=${cliId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    return Axios(config)
  }

  static requestEmailForgotPassword(email: any) {
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/auth-service/auth/recupera?email=${email}`,
    };

    return Axios(config)
  }

  static async requestLoginDev() {
    var config: any = {
      method: 'post',
      url: `${API_URL}/api/auth-service/auth/login`,
      headers: { 'Authorization': 'Basic cmljYXJkby52YXpxdWV6QGF4eGlzLmNvbS5teDpyaWNoYXJk' }
    };

    return await Axios(config)
  }

  static deleteDesign(designId: any, token: string) {
    var config: any = {
      method: 'delete',
      url: `${API_URL}/api/backend-service/data/disenio/${designId}`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    };

    return Axios(config)
  }

  static getRepresentantes(token: string) {
    var config: any = {
      method: 'get',
      url: `${API_URL}/api/backend-service/data/representante`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    };

    return Axios(config)
  }

  static deleteRepresentante(representanteId: any, token: string) {
    var config: any = {
      method: 'delete',
      url: `${API_URL}/api/backend-service/data/representante/${representanteId}`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    };

    return Axios(config)
  }

  static updateRepresentante(repId: any, data: any, token: any) {
    var config: any = {
      method: 'put',
      url: `${API_URL}/api/backend-service/data/representante/${repId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    return Axios(config)
  }

  static getReceivingRepresentatives(cliId: any, token: any) {
    var config: any = {
      method: 'get',
      url: `${API_URL}/api/backend-service/data/clientereceptor/search/findByCliId?cliId=${cliId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };

    return Axios(config)
  }

  static postReceivingRepresentatives(representative: any, token: any) {
    var data = JSON.stringify(representative);

    var config: any = {
      method: 'post',
      url: `${API_URL}/api/backend-service/data/clientereceptor`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    return Axios(config)
  }

  static deleteReceivingRepresentatives(representativeId: any, token: any) {
    var config: any = {
      method: 'delete',
      url: `${API_URL}/api/backend-service/data/clientereceptor/${representativeId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };

    return Axios(config)
  }

  static getDataManagement(cliId: any, token: any) {
    var config: any = {
      method: 'get',
      url: `${API_URL}/api/backend-service/data/clientedatos/search/findByCliId?cliId=${cliId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };

    return Axios(config)
  }

  static postDataManagement(dataManagment: any, token: any) {

    var data = JSON.stringify(dataManagment);

    var config: any = {
      method: 'post',
      url: `${API_URL}/api/backend-service/data/clientedatos`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    return Axios(config)
  }

  static putDataManagement(dataManagment: any, repId: any, token: any) {
    var data = JSON.stringify(dataManagment);

    var config: any = {
      method: 'put',
      url: `${API_URL}/api/backend-service/data/clientedatos/${repId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    return Axios(config)
  }




  static modificaUsuario(user: any, token: string) {
    //let dataEncrypt = EncryptService.encryptData(user);
    return RequestService.sendPostRequest(user, `usuario_sistema/usuario-sistema-update`, token);
  } // modificaUsuario

  static modificaPassword(user: any, token: string) {
    let dataEncrypt = EncryptService.encryptData(user);
    return RequestService.sendPostRequest(user, `usuario_sistema/psw-sistema-update`, token);
  } // modificaUsuario

  static getUser(id_usuario_sistema: any, token: any) {
    let dataEncrypt: any = EncryptService.encryptData(id_usuario_sistema);
    return RequestService.sendGetRequest(`usuario_sistema/obtenerUsuario/${dataEncrypt}`, token);
  } // getAUser

  static getAllUserPush(token: any) {
    return RequestService.sendGetRequest(`processrequest/apiget/getUsuariosToken`, token);
  } // modificaTipoObra

  static getUsuarios(data: any, token: string) {
    return RequestService.sendPostRequest(data, `processrequest/apipost/getUsuarios`, token);
  }

  static updateUsuarioSistema(data: any, token: string) {
    return RequestService.sendPostRequest(data, `processrequest/apipost/updateUsuarioSistema`, token);
  }

  static getUsuarioProveedor(token: any) {
    return RequestService.sendGetRequest(`processrequest/apiget/getUsuarioProveedor`, token);
  }

  static setUsuarioSistema(data: any, token: string) {
    //let dataEncrypt: any = EncryptService.encryptData(data);
    return RequestService.sendPostRequest(data, `usuario_sistema/setProveedor`, token);
  }

  //Olvide contraseña
  static recoverUsuario(usuario: any) {
    let dataEncrypt: any = EncryptService.encryptData(JSON.stringify(usuario));
    return RequestService.sendPostRequest({ data: dataEncrypt }, 'usuario_sistema/recoverUsuario');
  } // recoverUsuario

  static validaRecover(usuario: any) {
    let dataEncrypt: any = EncryptService.encryptData(JSON.stringify(usuario));
    return RequestService.sendPostRequest({ data: dataEncrypt }, 'usuario_sistema/validaRecover');
  } // validaRecover

  static cambiaRecover(password: any) {
    let dataEncrypt: any = EncryptService.encryptData(JSON.stringify(password));
    return RequestService.sendPostRequest({ data: dataEncrypt }, 'usuario_sistema/cambiaRecover');
  } // cambiaRecover
}
