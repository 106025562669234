import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import authGuardService from '../service/authGuardService';
import tokenServices from '../service/token.service';
import UserService from '../service/user.service';
import AppMenu from './VerticalMenu/AppMenu';

const AppTopbar = (props: any) => {

    const [user, setUser] = useState<any>(null);
    const [password, setPassword] = useState<string>('')
    const [passwordValid, setPasswordValid] = useState<boolean>(true);
    const [displayBasic, setDisplayBasic] = useState(false);

    const onHide = () => {
        setDisplayBasic(false)
    }
    const onClick = () => {
        setDisplayBasic(true)
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={() => onHide()} className="p-button-outlined mr-2" />
                <Button label="Continuar" onClick={() => checkPassword()} autoFocus />
            </div>
        );
    }

    const checkPassword = () => {
        if (password) {
            const user = authGuardService.getUser()
            let datos
            datos = user.username + ":" + password;
            const encodedString = Buffer.from(datos).toString('base64');
            UserService.loginUsuario(encodedString).then(async res => {
                const response: any = res;
                if (response.error == 'Unauthorized') {
                    setPasswordValid(false)
                } else if (response.data.username) {
                    setPasswordValid(true)
                    onHide()
                    tokenServices.checkTokenTimeout()
                    history.push("/olvcontra")
                } else {
                    setPasswordValid(false)
                }
            }).catch(e => {
                setPasswordValid(false)
            })
        }
    }

    useEffect(() => {
        setUser(authGuardService.getUser())
    }, []);

    const onTopbarSubItemClick = (event: any) => {
        navigateToProfile()
    }

    const history = useHistory();

    const logout = () => {
        authGuardService.logout()
        history.push("/");
    }

    const navigateToProfile = () => {
        tokenServices.checkTokenTimeout()
        history.push("/perfil")
    }

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                    <button className="logo p-link logo-espe">
                        <img src='../../assets/layout/images/Algorithia-logoMorado.png' />
                    </button>

                </div>

                <AppMenu model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                            <div onClick={props.onTopbarItemClick}>
                                <Avatar label={user?.nombre[0]} icon="pi pi-user" className="mr-2" size="large" shape="circle" style={{
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '17px',
                                    lineHeight: '24px',
                                    textTransform: 'uppercase',
                                    color: '#FFFFFF',
                                    background: '#472CB4'
                                }} />
                                <i className="pi pi-angle-down" style={{ color: '#5C39E9' }}></i>
                                {/*    <span>{user?.name ? user.name : ''}</span> */}
                            </div>
                            <ul className="fadeInDown">
                                <li className='text-center perfil'>
                                    <h6>{user?.nombre}</h6>
                                    <small>{user?.authorities[0]}</small>
                                    <p className='gris'>{user?.username}</p>
                                </li>
                                <li role="menuitem" className='perfil-menu'>
                                    <button className="p-link" onClick={() => onClick()}>
                                        <i className="pi pi-key"></i>
                                        <span>Cambiar contraseña</span>
                                    </button>
                                </li>
                                <li role="menuitem" className='perfil-menu'>
                                    <button className="p-link" onClick={logout}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <span>Cerrar Sesión</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>


                <Dialog
                    header="Contraseña actual"
                    visible={displayBasic}
                    style={{ width: '25vw' }}
                    onHide={() => onHide()}
                    footer={renderFooter()}
                >
                    <div className='col-12 md:col-12'>
                        <div className="p-field">
                            <label style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#25303C'
                            }}>Contraseña</label>
                            <Password
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña"
                                className={!passwordValid ? "p-invalid block" : ''}
                                feedback={false}
                                toggleMask
                            />
                            {!passwordValid && <small id="username2-help" className="p-error block" style={{ marginTop: 4 }}><i className="pi pi-exclamation-circle mr-2"></i>Contraseña incorrecta</small>}

                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default AppTopbar;