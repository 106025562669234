import authGuardService from "./authGuardService";
import LocalService from "./local.service";

export default class tokenServices {
    static checkTokenTimeout() {
        const currentTime = Date.now();
        const tokenTimeOut = authGuardService.getTokenTimeout()
        if (currentTime > tokenTimeOut) return this.deleteTokenTimeOut()
        else return true
    }
    static setTokenTimeOut(ms: number) {
        const currentTime = Date.now();
        const AddedTime = currentTime + ms
        LocalService.setJsonValue('tokenTimeout', AddedTime)
    }
    
    static deleteTokenTimeOut() {
        authGuardService.logout()
    }
}